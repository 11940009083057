import { configureApi } from 'react-reqq-lite';
import { get } from 'lodash';
import { toastError } from 'react-geek-toast';
import { persistStorage } from 'modules/common/helpers';

const store = configureApi({
  endpoint: process.env.REACT_APP_END_POINT,
  requestHeaders: () => {
    const token = persistStorage.get('token');
    const header = {
      'Content-Type': 'application/json',
    };
    if (token) {
      header.Authorization = `Bearer ${token}`;
    }
    return header;
  },
  timeout: 180000,
  // cacheStorage: 'WEBSQL',
  onError: (err) => {
    const err_message = get(err, 'response.message');
    if (err.status === 422) {
      const message = get(
        err,
        `response.errors.${Object.keys(get(err, 'response.errors'))[0]}.0`
      );
      toastError(message || 'Unprocessable Entity!');
      return;
    }
    if (err.status === 400) {
      if ((window?.location?.pathname || '').indexOf('/scan') > -1) return;
      toastError(err_message || 'Bad Request!');
      return;
    }
    if (err.status === 500) {
      toastError(err_message || 'Internal Server Error!');
      return;
    }
    if (err.status === 403) {
      localStorage.clear();
      sessionStorage.clear();
      toastError(err_message || 'Forbidden!');
      if (
        get(err, 'response.error') === 'token_expired' ||
        get(err, 'response.error') === 'token_invalid'
      )
        window.location.href = '/app';
      return;
    }
    if (err.status === 0) {
      toastError('Unable to connect to web service');
      return;
    }
    console.warn('unhandled error', err); // eslint-disable-line
  },
});

export default store;
