// GlobalStateContext.js
import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
	const a = parseInt(localStorage.getItem('textIndex'), 10) || 0;
	const [globalVariable, setGlobalVariable] = useState(a);

	return (
		<GlobalStateContext.Provider value={{ globalVariable, setGlobalVariable }}>
			{children}
		</GlobalStateContext.Provider>
	);
};
GlobalStateProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export const useGlobalState = () => useContext(GlobalStateContext);
