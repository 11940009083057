import { transformIncluded } from 'modules/common/helpers';
import { pick } from 'lodash';

const identifyStatus = (case_status, health_status) => {
  if (['0001', '0002', '0003'].indexOf(case_status) > -1) {
    return case_status;
  }
  return health_status;
};

export const transformProfile = (raw, included = []) => {
  const data = transformIncluded(raw, included);
  return {
    id: data.attributes?.is_reg_completed ? data?.attributes?.uuid : null,
    is_guest: data.attributes?.is_guest || 0,
    mobile_number: data?.attributes?.mobile_number || '',
    age: data?.attributes?.age || '',
    passport_country: data?.attributes?.passport_country || '',
    passport: data?.attributes?.passport || '',
    has_first_dose: data?.attributes?.has_first_dose || '',
    has_second_dose: data?.attributes?.has_second_dose || '',
    has_booster: data?.attributes?.has_booster || '',
    has_rt_pcr: data?.attributes?.has_rt_pcr || '',
    has_attested: data?.attributes?.has_attested,
    verification_status: data?.attributes?.verification_status || '',
    is_customs_completed: data?.attributes?.is_customs_completed || '',
    is_health_declaration_completed:
      data?.attributes?.is_health_declaration_completed || '',
    full_name: `${data?.included?.logbook?.attributes?.name || ''} ${
      data?.included?.logbook?.attributes?.middle_name || ''
    } ${data?.included?.logbook?.attributes?.last_name || ''}`,
    photo_url: data?.attributes?.photo_url || '',
    latest_visit_history_uuid:
      data?.attributes?.latest_visit_history_uuid || '',
    first_name: data?.attributes?.first_name || '',
    middle_name: data?.attributes?.middle_name || '',
    last_name: data?.attributes?.last_name || '',
    gender: data?.attributes?.gender || '',
    birth_date: data?.included?.logbook?.attributes?.birth_date || '',
    region_code: data?.included?.logbook?.attributes?.region_code || '',
    province_code: data?.included?.logbook?.attributes?.province_code || '',
    municipality_code:
      data?.included?.logbook?.attributes?.municipality_code || '',
    barangay_code: data?.included?.logbook?.attributes?.barangay_code || '',
    email: data?.included?.logbook?.attributes?.email || '',
    company_name: data?.included?.logbook?.attributes?.company_name || '',
    full_address: data?.included?.logbook?.attributes?.full_address || '',
    health_status: data?.attributes?.health_status || '',
    status: identifyStatus(
      data?.attributes?.case_status,
      data?.attributes?.health_status
    ),
    symptoms: data?.attributes?.symptoms,
    verification_remarks: data?.attributes?.verification_remarks,
    is_reg_completed: data?.attributes?.is_reg_completed,
    is_scanner: data?.attributes?.is_front_liner,
  };
};

export const payloadProfile = ({ first_name, ...payload }) => ({
  name: first_name,
  ...pick(payload, [
    'photo_url',
    'passport_country',
    'passport',
    'first_name',
    'middle_name',
    'last_name',
    'gender',
    'birth_date',
    'region_code',
    'province_code',
    'municipality_code',
    'barangay_code',
    'email',
    'company_name',
  ]),
});

export const payloadSymptoms = (payload) => ({
  geoloc: null,
  ...payload,
});

export const transformScanResult = (qr_value, raw) => {
  if (raw?.data?.type === 'location_qr_code') {
    return {
      type: 'ESTABLISHMENT',
      qr_value,
      qr_type: raw?.data?.attributes?.qr_type || '',
      logo: raw?.data?.attributes?.logo || '',
      name: raw?.data?.attributes?.name || '',
      branch: raw?.data?.attributes?.branch || '',
      label: raw?.data?.attributes?.label || '',
      geoloc: raw?.data?.attributes?.geoloc || '',
    };
  }

  if (raw?.data?.type === 'registrant') {
    return {
      type: 'USER',
      qr_value,
      full_name: raw?.data?.attributes?.name || '',
      company_name: raw?.data?.attributes?.company_name || '',
      municipality_name: raw?.data?.attributes?.municipality || '',
      status: identifyStatus(
        raw?.data?.attributes?.case_status,
        raw?.data?.attributes?.status
      ),
      health_status: raw?.data?.attributes?.status || '',
    };
  }

  return {
    type: 'NOT_FOUND',
    qr_value,
    message:
      raw?.message ||
      'QR Code not found! Please make sure that you are using a valid Travel Marianas QR Code',
  };
};

export const transformVisit = (raw) => {
  return {
    status: raw?.status === 200 ? 'ALLOWED' : 'NOT_ALLOWED',
    title: raw?.response?.title,
    message:
      raw?.response?.message ||
      'Welcome! Please wear mask, wash your hands and maintain social distancing! Stay safe!',
  };
};

export const transformScannerScan = (qr_value, raw) => {
  if (raw?.data?.type === 'registrant') {
    return {
      type: 'USER',
      qr_value,
      full_name: raw?.data?.attributes?.name || '',
      company_name: raw?.data?.attributes?.company_name || '',
      municipality_name: raw?.data?.attributes?.municipality || '',
      status: identifyStatus(
        raw?.data?.attributes?.case_status,
        raw?.data?.attributes?.status
      ),
      health_status: raw?.data?.attributes?.status || '',
    };
  }
  return {
    type: 'NOT_FOUND',
    qr_value,
    message:
      raw?.message ||
      'QR Code not found! Please make sure that you are using a valid Travel Marianas QR Code',
  };
};
