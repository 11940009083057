import React from 'react';
// import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import Splash from 'modules/partial/containers/Splash';
import Header from 'modules/digital-logbook/components/Header';
import history from 'setup/history';
import placeholder from 'assets/images/logo-icon.svg';
import { showModal } from 'modules/common/components/Modal';
import { termsModalContent } from 'modules/digital-logbook/components/Terms';
import { useLogbookQr } from './hooks';
import GuestLogbookForm from './GuestLogbookForm';

function GuestLogbookContainer() {
  const { uuid } = useParams();
  const [isLoading, data] = useLogbookQr(uuid);

  const [continueAsGuest, setContinueAsGuest] = React.useState(false);
  const [ended, setEnded] = React.useState(false);

  const handleViewTerms = (e) => {
    e.preventDefault();
    showModal({
      title: false,
      content: termsModalContent,
      className: 'modal-lg',
    });
  };

  const handleWebApp = () => {
    history.push('/digital-logbook');
  };

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      history.push({
        pathname: '/digital-logbook/scan',
        state: {
          scan: uuid,
        },
      });
    }
  }, [uuid]);

  if (isLoading) {
    return <Splash />;
  }
  if (data.error || isEmpty(data)) {
    return (
      <div className="w-full max-w-full sm:max-w-sm mx-auto bg-gradient-to-br from-gray-100 to-gray-300 flex flex-col h-full rounded-none sm:rounded-2xl overflow-hidden shadow-blue-2xl app-wrapper">
        <Header label="Travel Marianas QR Code" />
        <div className="flex-grow flex bg-gray-200">
          <div className="m-auto">{data.message || 'Invalid QR Code'}</div>
        </div>
      </div>
    );
  }

  if (continueAsGuest && !isEmpty(data)) {
    return (
      <div className="w-full max-w-full sm:max-w-sm mx-auto bg-gradient-to-br from-gray-100 to-gray-300 flex flex-col h-full rounded-none sm:rounded-2xl overflow-hidden shadow-blue-2xl app-wrapper">
        <Header
          label={ended ? 'Welcome' : 'Continue As Guest'}
          returnUrl={ended ? null : () => setContinueAsGuest(false)}
        />
        <div className="bg-white h-full rounded-t-3xl p-5 flex flex-col rounded-t-effect relative mt-3 rounded-t-effect z-10 flex-grow">
          <GuestLogbookForm qrData={data} onSuccess={() => setEnded(true)} />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-full sm:max-w-sm mx-auto bg-gradient-to-br from-gray-100 to-gray-300 flex flex-col h-full rounded-none sm:rounded-2xl overflow-hidden shadow-blue-2xl app-wrapper">
      <Header label="Travel Marianas QR" />
      <div className="bg-white h-full rounded-t-3xl p-5 flex flex-col rounded-t-effect relative mt-3 rounded-t-effect z-10 flex-grow">
        <div className="w-full px-5 m-auto text-center">
          <div>You are about to enter</div>
          <div className="flex justify-center">
            <img
              className="h-32 w-32 object-contain"
              src={data.logo || placeholder}
              alt={data.name}
            />
          </div>
          <div className="mb-4">
            <div className="text-2xl font-bold">{data.name}</div>
            <div className="text-lg">{data.branch}</div>
          </div>
          <div className="border-t py-4">
            By clicking below, you agree
            <br />
            with the{' '}
            <a href="/" onClick={handleViewTerms}>
              Terms and Conditions
            </a>
          </div>
          <div>
            <button
              className="btn primary w-full"
              type="button"
              onClick={handleWebApp}
            >
              Continue with Web App
            </button>
          </div>
          <div className="text-xs my-3 text-gray-800">Or</div>
          <div>
            <button
              className="btn light w-full"
              type="button"
              onClick={() => setContinueAsGuest(true)}
            >
              Continue as Guest
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

GuestLogbookContainer.propTypes = {};

export default GuestLogbookContainer;
