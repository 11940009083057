import React from 'react';
// import PropTypes from 'prop-types';
import logo from 'assets/images/logo-icon.svg';
import bg from 'assets/images/bg-splash.jpg';

function Splash() {
  return (
    <div
      className="fixed h-full w-full bg-white bg-no-repeat bg-cover bg-center flex top-0 left-0"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="m-auto">
        <img className="pulsate-fwd h-16" src={logo} alt="Brand" />
      </div>
    </div>
  );
}

Splash.propTypes = {};

export default Splash;
