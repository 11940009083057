export const DELETE_ACCOUNT = 'ACCOUNT/delete';
export const PROFILE = 'LOGBOOK/profile';
export const SYMPTOMS = 'LOGBOOK/symptoms';
export const SYMPTOMS_OPTIONS = 'LOGBOOK/symptoms_options';
export const AUTH_BY_OTP = 'LOGBOOK/auth_by_otp';
export const SCAN_RESULT = 'LOGBOOK/scan_result';
export const CONFIRM_VISIT = 'LOGBOOK/confirm_visit';
export const ACCOUNT_SCANNER_RESULT = 'LOGBOOK/account_scanner_result';
export const PURPOSE_OPTIONS = 'LOGBOOK/purpose_options';

export const GENERATE_TOKEN = 'LOGBOOK/generate_token';

export const MAP_WRAPPER_CLASS = {
  '0001': 'color-probable',
  '0002': 'color-suspected',
  '0003': 'color-confirmed',
  SEVERE: 'color-severe',
  MILD: 'color-mild',
  GOOD: 'color-good',
  '0004': 'color-cleared',
  '0005': 'color-good',
};

export const MAP_COLOR = {
  SEVERE: 'severe',
  MILD: 'mild',
  GOOD: 'good',
};
