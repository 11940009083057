import React from 'react';
import PropTypes from 'prop-types';
// import { pick } from 'lodash';
// import FormAddress from 'modules/common/forms/FormAddress';
import FormInput from 'modules/common/forms/FormInput';
import FormSelect from 'modules/common/forms/FormSelect';
// import FormDate from 'modules/common/forms/FormDate';
import FormPhoneNumber from 'modules/common/forms/FormPhoneNumber';
import { useLanguage } from '../common/components/Language';

const GENDER_OPTIONS = [
	{ label: 'MALE', value: 'MALE' },
	{ label: 'FEMALE', value: 'FEMALE' },
];

function ProfileForm({ form, setForm }) {
	const [text] = useLanguage();
	return (
		<>
			<div className="mb-2">
				<FormInput
					name="name"
					onChange={setForm}
					value={form.name}
					label="First Name"
					className="uppercase"
					required
				/>
			</div>
			<div className="mb-2">
				<FormInput
					name="middle_name"
					onChange={setForm}
					value={form.middle_name}
					label="Middle Name"
					className="uppercase"
				/>
			</div>
			<div className="mb-2">
				<FormInput
					name="last_name"
					onChange={setForm}
					value={form.last_name}
					label="Last Name"
					className="uppercase"
					required
				/>
			</div>
			<div className="mb-2">
				<FormInput
					name="age"
					onChange={setForm}
					value={form.age}
					label="Age"
					type="number"
					required
				/>
			</div>
			{/* <div className="mb-2">
        <FormDate
          name="birth_date"
          onChange={setForm}
          value={form.birth_date}
          label="Birth Date"
          required
        />
      </div> */}
			<div className="mb-2">
				<FormSelect
					name="gender"
					onChange={setForm}
					value={form.gender}
					label={text('SELECT')}
					required
					options={GENDER_OPTIONS}
					placeholder="- Select -"
				/>
			</div>
			{/* <div className="mb-2">
        <FormAddress
          onChange={setForm}
          value={pick(form, [
            'region_code',
            'province_code',
            'municipality_code',
            'barangay_code',
          ])}
          required
        />
      </div> */}
			<div className="mb-2">
				<input
					type="text"
					required
					value={form.mobile_number}
					className="h-0 w-0 absolute opacity-0 pointer-events-none"
					onChange={() => {}}
					onFocus={() => {
						try {
							const el = document.getElementById('phone-input');
							el.focus();
						} catch (err) {
							// do nothing
						}
					}}
				/>
				<FormPhoneNumber
					id="phone-input"
					name="mobile_number"
					onChange={setForm}
					value={form.mobile_number}
					label="Mobile Number"
					required
				/>
			</div>
			<div className="mb-2">
				<FormInput
					name="email"
					onChange={setForm}
					value={form.email}
					label="Email Address"
					type="email"
				/>
			</div>
			<div className="mb-2">
				<FormInput
					name="company_name"
					onChange={setForm}
					value={form.company_name}
					label="Company Name"
					className="uppercase"
				/>
			</div>
		</>
	);
}

ProfileForm.propTypes = {
	form: PropTypes.instanceOf(Object).isRequired,
	setForm: PropTypes.func.isRequired,
};

export default ProfileForm;
