import React, { Suspense, lazy, useLayoutEffect } from 'react';
import { useLocation, Switch, Route, Redirect } from 'react-router-dom';
import ErrorBoundary from 'modules/partial/containers/ErrorBoundary';
import PropTypes from 'prop-types';
import { useLocalPersistState } from 'modules/common/helpers';
import EN from 'assets/images/language/united.svg';
import JP from 'assets/images/language/japan.svg';
import KR from 'assets/images/language/south.svg';
import CN from 'assets/images/language/china.svg';
import {
	ModalMarker,
	AlertMarker,
	showModal,
	ModalClose,
} from 'modules/common/components/Modal';
import logo from 'assets/images/logo-horizontal.svg';
import graphicHero from 'assets/images/graphic-hero.svg';
import { HomeIcon } from '@heroicons/react/outline';
import Splash from 'modules/partial/containers/Splash';
import PWAState from 'modules/pwa/PWAState';
import GuestLogbookContainer from 'modules/guest-logbook/GuestLogbookContainer';
import OAuthPage from 'travel-modules/auth/containers/OAuthPage';
import { ToastMarker } from 'react-geek-toast';
import Language, { useLanguage } from 'modules/common/components/Language';
import PrintRoute from 'modules/print/containers/PrintRoute';
import Public from './Public';
import Maintenance from './Maintenance';
// import {
// 	ArrowPathIcon,
// 	ChartPieIcon,
// 	CursorArrowRaysIcon,
// 	FingerPrintIcon,
// 	SquaresPlusIcon,
//   } from '@heroicons/react/24/outline';
// import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
// import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid';

import {
	GlobalStateProvider,
	useGlobalState,
} from './modules/digital-logbook/components/globals';

const TravelRoot = lazy(() => import('travel-modules'));
const DigitalLogbookRoutes = lazy(
	() => import('modules/digital-logbook/containers/DigitalLogbookRoutes')
	// import('modules/partial/containers/Splash')
);
const WebViewContainer = lazy(() =>
	import('modules/partial/containers/WebViewContainer')
);

const LogoutAccountModal = ({ onClose }) => {
	const [text] = useLanguage();
	return (
		<>
			<ModalClose onClose={onClose} />
			<div className="py-4 px-5">
				<div className="mb-2 text-center">
					{text('LEAVE_MESSAGE')}
					<br />
				</div>
				<div className="flex">
					<a href="/app/logout" className="btn danger w-full">
						{text('CONFIRM')}
					</a>
					<button
						className="btn light w-full ml-2"
						type="button"
						onClick={onClose}
					>
						{text('CANCEL')}
					</button>
				</div>
			</div>
		</>
	);
};

LogoutAccountModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

const AppRoute = (props) => {
	const [text] = useLanguage();
	const [isGuest] = useLocalPersistState('is_guest', 0);
	const location = useLocation();
	const [token] = useLocalPersistState('token', null);
	const handleLogout = (e) => {
		e.preventDefault();
		if (isGuest) {
			showModal({
				title: 'Logout on your account',
				content: (onClose) => <LogoutAccountModal onClose={onClose} />,
			});
			return;
		}
		window.location.replace('https://landing.travel.mp/');
	};
	const { globalVariable } = useGlobalState();
	const textSizes = [
		'text-xs',
		'text-sm',
		'text-base',
		'text-lg',
		'text-xl',
		'text-2xl',
		'text-3xl',
		'text-4xl',
		'text-5xl',
	];

	return (
		<div className="h-full flex">
			{location.pathname === '/app' && token === null ? (
				<div className="w-1/2 hidden md:flex relative">
					<button
						type="button"
						className="btn light flex items-center space-x-2 justify-center absolute left-5 top-5"
						onClick={handleLogout}
					>
						<HomeIcon className="h-5 w-5" />
						<span className={`${textSizes[globalVariable]}`}>
							{text('BACK_TO_HOME')}
						</span>
					</button>
					{/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
					<div className="col-10 m-auto text-left pt-10">
						<div className="mx-10 pt-5">
							<div className="mb-2">
								<div className="text-lg font-bold text-center">
									{text('INSTRUCTIONS')}
								</div>

								<table className="table-auto border-spacing-2 w-full shadow-2xl rounded-lg">
									<thead className="bg-[#5f83a9]">
										<tr>
											<th
												className={` ${textSizes[globalVariable]} p-2 text-white`}
											>
												{text('STEP_1')} {text('SELECT_LANGUAGE')}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className="pl-5 pr-5 pb-2 pt-1 cursor-not-allowed">
												<div className="grid grid-cols-4 gap-4">
													<img
														src={EN}
														alt="lang"
														className="border border-slate-300 w-14 opacity-50"
													/>
													<img
														src={JP}
														alt="lang"
														className="border border-slate-300 w-14 opacity-50"
													/>
													<img
														src={KR}
														alt="lang"
														className="border border-slate-300 w-14 opacity-50"
													/>
													<img
														src={CN}
														alt="lang"
														className="border border-slate-300 w-14 opacity-50"
													/>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="mb-2">
								<table className="table-auto border-spacing-2 w-full shadow-2xl rounded-lg">
									<thead className="bg-[#5f83a9] ">
										<tr>
											<th
												className={` ${textSizes[globalVariable]} p-2 text-white`}
											>
												{text('STEP_2')} {text('SELECT_ACCOUNT_TYPE')}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 `}
											>
												<b>{text('GUEST_ACCESS')}</b>{' '}
												{text('GUEST_ACCESS_DESCRIPTION')}
											</td>
										</tr>
										<tr>
											<td className={` ${textSizes[globalVariable]} pl-5 pr-5`}>
												--{text('OR')}--
											</td>
										</tr>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 pb-1`}
											>
												<b>{text('FREQUENT_FLYER')}</b>{' '}
												{text('FREQUENT_FLYER_DESCRIPTION')}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="mb-2">
								<table className="table-auto border-spacing-2 w-full shadow-2xl rounded-lg">
									<thead className="bg-[#5f83a9]">
										<tr>
											<th
												className={` ${textSizes[globalVariable]} p-2 text-white`}
											>
												{text('GUEST_ACCESS')}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 pt-1`}
											>
												1. {text('GUEST_ACCESS_ONE')}
											</td>
										</tr>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 `}
											>
												2. {text('GUEST_ACCESS_TWO')}
											</td>
										</tr>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 `}
											>
												3. {text('GUEST_ACCESS_THREE')}
											</td>
										</tr>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 pb-1`}
											>
												4. {text('GUEST_ACCESS_FOUR')}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="mb-2">
								<table className="table-auto border-spacing-2 w-full rounded-lg shadow-2xl">
									<thead className="bg-[#5f83a9]">
										<tr>
											<th
												className={` ${textSizes[globalVariable]} p-2 text-white`}
											>
												{text('FREQUENT_FLYER')}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td
												className={`${textSizes[globalVariable]} pl-5 pr-5 pt-1`}
											>
												1. {text('FREQUENT_FLYER_ONE')}
											</td>
										</tr>
										<tr>
											<td className={`${textSizes[globalVariable]} pl-5 pr-5`}>
												2. {text('FREQUENT_FLYER_TWO')}
											</td>
										</tr>
										<tr>
											<td className={`${textSizes[globalVariable]} pl-5 pr-5`}>
												3. {text('FREQUENT_FLYER_THREE')}
											</td>
										</tr>
										<tr>
											<td className={`${textSizes[globalVariable]} pl-5 pr-5`}>
												4. {text('FREQUENT_FLYER_FOUR')}
											</td>
										</tr>
										<tr>
											<td className={`${textSizes[globalVariable]} pl-5 pr-5`}>
												5. {text('FREQUENT_FLYER_FIVE')}
											</td>
										</tr>
										<tr>
											<td className={`${textSizes[globalVariable]} pl-5 pr-5`}>
												6. {text('FREQUENT_FLYER_SIX')}
											</td>
										</tr>
										<tr>
											<td
												className={`${textSizes[globalVariable]} pl-5 pr-5 pb-1`}
											>
												7. {text('FREQUENT_FLYER_SEVEN')}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="mb-2">
								<table className="table-auto border-spacing-2 w-full shadow-2xl rounded-lg">
									<thead className="bg-[#5f83a9]">
										<tr>
											<th
												className={` ${textSizes[globalVariable]} p-2 text-white`}
											>
												{text('FREQUENT_FLYER_SIGNUP')}
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 pt-1`}
											>
												1. {text('FREQUENT_FLYER_ONE')}
											</td>
										</tr>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 `}
											>
												2. {text('FREQUENT_FLYER_TWO')}
											</td>
										</tr>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 `}
											>
												3. {text('FREQUENT_FLYER_THREE')}
											</td>
										</tr>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 `}
											>
												3. {text('FREQUENT_FLYER_REGISTRATION')}
											</td>
										</tr>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 `}
											>
												4. {text('FREQUENT_FLYER_FOUR')}
											</td>
										</tr>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 `}
											>
												5. {text('FREQUENT_FLYER_FIVE')}
											</td>
										</tr>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 `}
											>
												6. {text('FREQUENT_FLYER_SIX')}
											</td>
										</tr>
										<tr>
											<td
												className={` ${textSizes[globalVariable]} pl-5 pr-5 pb-3`}
											>
												7. {text('FREQUENT_FLYER_SEVEN')}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="mt-4 text-lg font-semibold text-center">
								{text('WE_LOOK_FORWARD')}
							</div>
						</div>
					</div>
					{/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
				</div>
			) : (
				''
			)}
			<div className="flex-grow bg-gradient-to-br from-gray-100 to-gray-300 justify-center flex flex-col h-full relative items-center">
				<Route {...props} />
			</div>
		</div>
	);
};

function App() {
	const location = useLocation();
	useLayoutEffect(() => {
		if (location.pathname === '/') {
			window.location.replace('https://landing.travel.mp');
		}
	});
	return (
		<GlobalStateProvider>
			<ErrorBoundary>
				<PWAState />
				<ToastMarker />
				<ModalMarker />
				<AlertMarker />
				<Language />
				{process.env.REACT_APP_MAINTENANCE === 'true' ? (
					<Maintenance />
				) : (
					<Suspense fallback={<Splash />}>
						<Switch>
							<Route
								path="/print/qr/:uuid/:name/:size"
								component={PrintRoute}
							/>
							<AppRoute
								path="/g/logbook/:uuid"
								component={GuestLogbookContainer}
							/>
							<AppRoute path="/app" component={TravelRoot} />
							<AppRoute
								path="/digital-logbook"
								component={DigitalLogbookRoutes}
							/>
							<AppRoute path="/digital-logbook">
								<Redirect to="/app" />
							</AppRoute>
							<Route path="/webview" component={WebViewContainer} />
							<Route path="/oauth" component={OAuthPage} />
							<Route path="/" component={Public} />
						</Switch>
					</Suspense>
				)}
			</ErrorBoundary>
		</GlobalStateProvider>
	);
}

export default App;
