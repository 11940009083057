import React, { Suspense, lazy } from 'react';
import { useLocation, Switch, Route, Redirect } from 'react-router-dom';
import Landing from 'modules/partial/containers/Landing';
import Footer from 'modules/partial/components/Footer';
import Page404 from 'modules/partial/containers/Page404';
import Splash from 'modules/partial/containers/Splash';
import LocaleSwitcher from 'modules/common/components/LocaleSwitcher';

const Hotlines = lazy(() => import('modules/partial/containers/Hotlines'));
const Support = lazy(() => import('modules/partial/containers/Support'));
const PrivacyStatement = lazy(() =>
	import('modules/partial/containers/PrivacyStatement')
);
const Help = lazy(() => import('modules/partial/containers/Help'));
const Faq = lazy(() => import('modules/partial/containers/Faq'));
const HowToRegister = lazy(() =>
	import('modules/partial/containers/HowToRegister')
);
const ContactUs = lazy(() => import('modules/partial/containers/ContactUs'));

const Memo = lazy(() => import('modules/partial/containers/Memo'));

function Public() {
	const location = useLocation();
	return (
		<div>
			{location.pathname === '/' ? (
				<div className="hidden">asd</div>
			) : (
				<div>
					<div className="flex flex-col h-full">
						<div className="flex-grow bg-main-hero">
							<div className="absolute">
								<LocaleSwitcher />
							</div>
							<Suspense fallback={<Splash />}>
								<Switch>
									<Route exact path="/" component={Landing} />
									<Route path="/memo" component={Memo} />
									<Route path="/hotlines" component={Hotlines} />
									<Route
										path="/privacy-statement"
										component={PrivacyStatement}
									/>
									<Route path="/faqs" component={Faq} />
									<Route path="/contact-us" component={ContactUs} />
									<Route path="/how-to-register" component={HowToRegister} />
									{/* <Route exact path="/travel-restrictions" component={Support} /> */}
									<Route exact path="/support" component={Support} />
									<Route exact path="/help" component={Help} />
									<Route path="/logout">
										<Redirect to="/" />
									</Route>
									<Route component={Page404} />
								</Switch>
							</Suspense>
						</div>
						<Footer />
					</div>
				</div>
			)}
		</div>
	);
}

Public.propTypes = {};

export default Public;
