import React from 'react';
// import PropTypes from 'prop-types';
import { HiX } from 'react-icons/hi';
import logo from 'assets/images/logo.svg';
import DataPrivacy from 'modules/partial/components/DataPrivacy';
import { showModal } from 'modules/common/components/Modal';

export const termsModalContent = (onClose) => (
  <div className="p-6">
    <div className="flex justify-end">
      <button type="button" onClick={onClose}>
        <HiX className="h-6 w-6" />
      </button>
    </div>
    <DataPrivacy />
    <div className="mt-2">
      <button className="btn primary w-full" type="button" onClick={onClose}>
        Ok
      </button>
    </div>
  </div>
);

export const showTermsModal = () => {
  showModal({
    title: false,
    content: termsModalContent,
    className: 'modal-lg',
  });
};

function Terms() {
  // const duration = '1 month';
  const email = 'help@safetravelsmarianas.com';
  return (
    <div className="space-y-4">
      <img className="h-10 mt-2 mb-4 mx-auto" src={logo} alt="Brand" />
      <hr />
      <p className="font-bold text-lg">
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Terms of Use of Electronic Logbook and Privacy Notice
        </a>
      </p>
      <p>
        This Terms of Use and Privacy Notice explains what information we
        collect and process, why we do so; and your rights with regard to your
        information. We collect your information pursuant to Republic Act No.
        11332 (the “Mandatory Reporting of Notifiable Diseases and Health Events
        of Public Health Concern Act”) and in accordance with Republic Act No.
        10173 (the “Data Privacy Act”).
      </p>
      <p>
        We may amend this Terms of Use and Privacy Notice from time to time.
        When certain changes require your consent, we shall communicate the
        changes to you and ask for your consent. Otherwise, changes take effect
        immediately upon posting.
      </p>
      <p>
        By using the Electronic Logbook, you voluntarily consent to the
        collection and processing of your personal information.
      </p>

      <p className="font-bold">What information we collect</p>
      <p>
        When using the Electronic Logbook, we collect the following information:
      </p>
      <ol className="list-disc pl-6">
        <li>Mobile Number</li>
        <li>Name</li>
        <li>Birthday</li>
        <li>Gender</li>
        <li>Residence</li>
        {/* <li>Company Name</li> */}
        {/* <li>Company Address</li> */}
        {/* <li>Photo</li> */}
      </ol>

      <p className="font-bold">Why we collect your information</p>
      <p>
        We collect your information for record keeping purpose only, to keep
        track of the people coming in and out of the premises and for a quick,
        efficient and reliable contact tracing.
      </p>

      <p className="font-bold">
        How we use, share, and retain your information
      </p>
      <p>
        The information we collect will be stored in a secured database
        repository which will be used for record keeping purpose and contact
        tracing only.
      </p>
      {/* <p>
        We will only use your information for a limited time. We will keep your
        information for a period of {duration}, after which these are securely
        deleted unless retention is required by law.
      </p> */}
      <p>
        Should you wish to have your Personal Information deleted and destroyed
        or you wish to withdraw your consent in the processing of your personal
        information, you may do so by contacting us.
      </p>

      <p className="font-bold">Your Rights as a Data Subject</p>
      <ol className="list-disc pl-6">
        <li>Right to be informed</li>
        <li>Right to access</li>
        <li>Right to object</li>
        <li>Right to erasure of blocking</li>
        <li>Right to rectify</li>
        <li>Right to data portability</li>
        <li>Right to file a complaint</li>
        <li>Right to damages</li>
      </ol>

      <p className="font-bold">How we secure your information</p>
      <p>
        We implement reasonable and appropriate physical, technical, and
        organizational measures to prevent the loss, destruction, misuse, or
        alteration of your information. While there is no guarantee that
        information sent over the internet is fully secured, we keep and protect
        your information using a secured server behind a firewall, encryption,
        and security controls.
      </p>

      <p className="font-bold">Exercising your rights</p>
      <p>
        The Data Privacy Act gives you certain rights to your information. If
        you want to exercise your rights or learn more about how Safify.com
        processes your information, please contact our Data Protection Officer
        at <a href={`mailto:${email}?subject=Data Privacy`}>{email}</a>
      </p>
    </div>
  );
}

Terms.propTypes = {};

export default Terms;
