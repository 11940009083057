import React from 'react';
import PropTypes from 'prop-types';
import FormInput from 'modules/common/forms/FormInput';
// eslint-disable-next-line import/no-cycle
import { useSetEmailForOTP } from '../hooks';

function RegisterEmailForOTP({ payload, onClose, onSuccess }) {
  const [form, setForm] = React.useState({
    email: '',
  });
  const [isLoading, setEmailForOTP] = useSetEmailForOTP();
  const handleSubmit = (e) => {
    e.preventDefault();
    setEmailForOTP({ ...payload, ...form }, () => {
      onSuccess(`OTP has been sent to ${form.email}`, form.email);
      onClose();
    });
  }
  return (
    <form className="p-5" onSubmit={handleSubmit}>
      <div className="mb-2">
        <FormInput
          name="email"
          onChange={setForm}
          value={form.email}
          type="email"
          label="Enter email address"
        />
      </div>
      <div className="flex space-x-2">
        <button className="btn primary w-full" type="submit" disabled={isLoading}>Send OTP</button>
        <button className="btn outline-line w-full" type="button" onClick={onClose}>Cancel</button>
      </div>
    </form>
  )
}

RegisterEmailForOTP.propTypes = {
  payload: PropTypes.instanceOf(Object).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RegisterEmailForOTP;
