import { toastSuccess } from 'react-geek-toast';
import { req } from 'react-reqq-lite';
import { LOGBOOK_QR, SUBMIT_LOGBOOK } from './constants';
import { transformLogbookQr } from './transformers';

export const logbookQr = (uuid) => {
  req.get({
    key: LOGBOOK_QR,
    url: `/api/v1/scan_qr`,
    params: { uuid },
    transform: (res) => transformLogbookQr(uuid, res.data),
    onError: (res) => {
      req.set(LOGBOOK_QR, {
        error: true,
        message: res?.response?.message || 'Invalid QR Code',
      });
    },
  });
};

export const submitLogbook = (payload, onSuccess) => {
  req.post({
    key: SUBMIT_LOGBOOK,
    url: '/api/v1/scan_qr/confirm',
    payload,
    onSuccess: (res) => {
      toastSuccess('Welcome!');
      if (onSuccess) onSuccess(res);
    },
  });
};
