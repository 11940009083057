import React from 'react';
import { showModal } from 'modules/common/components/Modal';
import HowToDeleteAccountModal from 'modules/digital-logbook/modals/HowToDeleteAccountModal';
import { useLanguage } from 'modules/common/components/Language';

function DataPrivacy() {
	const [text] = useLanguage();
	const handleDelete = (e) => {
		e.preventDefault();
		showModal({
			title: 'Delete or Deactivate your account',
			content: (onClose) => <HowToDeleteAccountModal onClose={onClose} />,
		});
	};

	return (
		<div className="prose space-y-3">
			<h1 className="text-lg">
				<a href="https://www.safetravelsmarianas.com/">
					<span>{text('PRIVACY_POLICY')}</span>
				</a>
			</h1>
			<div className="text-justify">
				<span>
					<a
						href="/"
						onClick={handleDelete}
						className="pointer-events-auto text-primary-300"
					>
						{text('DELETE_ACCOUNT')}
					</a>
				</span>
			</div>
			<div>{text('LAST_MODIFIED')}</div>
			<div>
				<strong>
					<span className="text-left underline">{text('INTRODUCTION')}</span>
				</strong>
			</div>
			<div className="text-justify">
				{text('INTRODUCTION_FIRST_PARAGRAPH')}{' '}
				<a href={`https://${text('INTRODUCTION_FIRST_LINK')}`}>
					{text('INTRODUCTION_FIRST_LINK')}
				</a>{' '}
				{/* End of Replace links of `INTRODUCTION_SECOND_LINK and INTRODUCTION_THIRD_LINK` is intentional due to client request and relayed by ms.Shariah and the reason is both sites are covid related which client don't need yet. */}
				{text('INTRODUCTION_FIRST_PARAGRAPH_TWO')}
			</div>
			<div className="text-justify">
				<span>{text('INTRODUCTION_SECOND_PARAGRAPH')}</span>
			</div>

			<h1 className="text-base text-left font-bold italic py-4">
				{text('TERMS_OF_USE')}
			</h1>

			<div>
				<strong>
					<span className="underline">{text('ACCEPTANCE')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('ACCEPTANCE_FIRST_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				{text('ACCEPTANCE_SECOND_PARAGRAPH')}{' '}
				<b>{text('ACCEPTANCE_SECOND_PARAGRAPH_BOLD')}</b>
				<span> {text('ACCEPTANCE_SECOND_PARAGRAPH_TWO')}</span>
			</div>
			<div className="text-justify">
				<span>{text('ACCEPTANCE_THIRD_PARAGRAPH')}</span>
			</div>

			<div>
				<strong>
					<span className="underline">{text('CHANGES_TO_THE_TERMS')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('CHANGES_TO_THE_TERMS_FIRST_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>{text('CHANGES_TO_THE_TERMS_SECOND_PARAGRAPH')}</span>
			</div>

			<div className="text-justify">
				<strong>
					<span className="underline">{text('ACCESSING_THE_SITE')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('ACCESSING_THE_SITE_FIRST_PARAGRAPH')}</span>
			</div>
			<span>{text('ACCESSING_THE_SITE_BULLET')}</span>
			<ul className="ml-6 my-1 list-decimal">
				<li aria-level="1">
					<span>{text('ACCESSING_THE_SITE_FIRST_BULLET')}</span>
				</li>
				<li aria-level="1">
					<span>{text('ACCESSING_THE_SITE_SECOND_BULLET')}</span>
				</li>
			</ul>
			<div className="text-justify">
				<span>{text('ACCESSING_THE_SITE_SECOND_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>{text('ACCESSING_THE_SITE_THIRD_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>{text('ACCESSING_THE_SITE_FOURTH_PARAGRAPH')}</span>
			</div>

			<div>
				<strong>
					<span className="underline">{text('INTEL_PROPERTY_RIGHTS')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('INTEL_PROPERTY_RIGHTS_FIRST_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>{text('INTEL_PROPERTY_RIGHTS_SECOND_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>
					<ul className="ml-2 my-1 list-disc">
						<li aria-level="1">{text('INTEL_PROPERTY_RIGHTS_FIRST_BULLET')}</li>
						<li aria-level="1">
							{text('INTEL_PROPERTY_RIGHTS_SECOND_BULLET')}
						</li>
						<li aria-level="1">{text('INTEL_PROPERTY_RIGHTS_THIRD_BULLET')}</li>
						<li aria-level="1">
							{text('INTEL_PROPERTY_RIGHTS_FOURTH_BULLET')}
						</li>
						<li aria-level="1">{text('INTEL_PROPERTY_RIGHTS_FIFTH_BULLET')}</li>
					</ul>
				</span>
			</div>
			<div>
				<span>{text('INTEL_PROPERTY_RIGHTS_MUST_NOT')}</span>
			</div>
			<div className="text-justify">
				<span>
					<ul className="ml-2 my-1 list-disc">
						<li aria-level="1">
							{text('INTEL_PROPERTY_RIGHTS_SEVENTH_BULLET')}
						</li>
						<li aria-level="1">
							{text('INTEL_PROPERTY_RIGHTS_EIGHTH_BULLET')}
						</li>
					</ul>
				</span>
			</div>
			<div className="text-justify">
				<span>{text('INTEL_PROPERTY_RIGHTS_THIRD_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>{text('INTEL_PROPERTY_RIGHTS_FOURTH_PARAGRAPH')}</span>
			</div>

			<div>
				<strong>
					<span className="underline">{text('TRADEMARKS')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('TRADEMARKS_PARAGRAPH')}</span>
			</div>

			<div>
				<strong>
					<span className="underline">{text('PROHIBITED_USES')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('PROHIBITED_USES_BULLET')}</span>
			</div>
			<div className="text-justify">
				<span>
					<ul className="ml-6 my-1 list-disc">
						<li aria-level="1">{text('PROHIBITED_USES_FIRST_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_SECOND_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_THIRD_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_FOURTH_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_FIFTH_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_SIXTH_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_SEVENTH_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_SEVENTH_TWO_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_EIGHTH_BULLET')}</li>
					</ul>
					<span>{text('PROHIBITED_USES_ADDITIONAL')}</span>
					<ul className="ml-6 my-1 list-disc">
						<li aria-level="1">{text('PROHIBITED_USES_NINTH_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_TENTH_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_ELEVENTH_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_TWELVETH_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_THIRTEEN_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_FOURTEEN_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_FIFTHTEEN_BULLET')}</li>
						<li aria-level="1">{text('PROHIBITED_USES_SIXTEEN_BULLET')}</li>
					</ul>
					<br />
					<span>{text('PROHIBITED_USES_SIXTEEN_TWO_BULLET')}</span>
					<br />
					<br />
					<span>
						{text('PROHIBITED_USES_SIXTEEN_THREE_BULLET')}{' '}
						<a href="mailto:info@travel.mp">
							{text('PROHIBITED_USES_SIXTEEN_THREE_BULLET_LINK')}.
						</a>
					</span>
				</span>
			</div>

			<div>
				<strong>
					<span className="underline">{text('CHANGES_TO_THE_WEBSITE')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('CHANGES_TO_THE_WEBSITE_PARAGRAPH')}</span>
			</div>

			<div>
				<strong>
					<span className="underline">{text('INFORMATION_ABOUT_YOU')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('INFORMATION_ABOUT_YOU_PARAGRAPH')}</span>
			</div>

			<div>
				<strong>
					<span className="underline">{text('LINKING_TO_THE_SITE')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('LINKING_TO_THE_SITE_PARAGRAPH')}</span>
			</div>

			<div>
				<strong>
					<span className="underline">{text('GEOGRAPHIC_RESTRICTIONS')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('GEOGRAPHIC_RESTRICTIONS_PARAGRAPH')}</span>
			</div>

			<div>
				<strong>
					<span className="underline">{text('DISCLAIMER_OF_WARRANTIES')}</span>
				</strong>
			</div>
			<div className="text-justify">
				{text('DISCLAIMER_OF_WARRANTIES_FIRST_PARAGRAPH')}{' '}
				{text('DISCLAIMER_OF_WARRANTIES_SECOND_PARAGRAPH')}
			</div>
			<div className="text-justify uppercase">
				{text('DISCLAIMER_OF_WARRANTIES_THIRD_PARAGRAPH')}
			</div>
			<div className="text-justify uppercase">
				{text('DISCLAIMER_OF_WARRANTIES_FOURTH_PARAGRAPH')}
			</div>
			<div className="text-justify uppercase">
				{text('DISCLAIMER_OF_WARRANTIES_FIFTH_PARAGRAPH')}
			</div>

			<div>
				<strong>
					<span className="underline">{text('LIMITATION_ON_LIABILITY')}</span>
				</strong>
			</div>
			<div className="text-justify uppercase">
				{text('LIMITATION_ON_LIABILITY_FIRST_PARAGRAPH')}
			</div>
			<div className="text-justify uppercase">
				{text('LIMITATION_ON_LIABILITY_SECOND_PARAGRAPH')}
			</div>

			<div>
				<strong>
					<span className="underline">{text('INDEMNIFICATION')}</span>
				</strong>
			</div>
			<div className="text-justify">{text('INDEMNIFICATION_PARAGRAPH')}</div>

			<div>
				<strong>
					<span className="underline">{text('GOVERNING_LAW')}</span>
				</strong>
			</div>
			<div className="text-justify">{text('GOVERNING_LAW_PARAGRAPH')}</div>
			<div className="text-justify">{text('GOVERNING_LAW_TWO_PARAGRAPH')}</div>

			<div>
				<strong>
					<span className="underline">{text('ARBITRATION')}</span>
				</strong>
			</div>
			<div className="text-justify">{text('ARBITRATION_PARAGRAPH')}</div>

			<div>
				<strong>
					<span className="underline">{text('LIMITATION_ON_TIME')}</span>
				</strong>
			</div>
			<div className="text-justify uppercase">
				{text('LIMITATION_ON_TIME_PARAGRAPH')}
			</div>

			<div>
				<strong>
					<span className="underline">{text('WAIVER_AND_SEVERABILITY')}</span>
				</strong>
			</div>
			<div className="text-justify">
				{text('WAIVER_AND_SEVERABILITY_PARAGRAPH')}
			</div>
			<div className="text-justify">
				{text('WAIVER_AND_SEVERABILITY_SECOND_PARAGRAPH')}
			</div>

			<div>
				<strong>
					<span className="underline">{text('ENTIRE_AGREEMENT')}</span>
				</strong>
			</div>
			<div className="text-justify">{text('ENTIRE_AGREEMENT_PARAGRAPH')}</div>

			<div>
				<strong>
					<span className="underline">{text('YOUR_COMMENTS')}</span>
				</strong>
			</div>
			<div className="text-justify">
				{text('YOUR_COMMENTS_PARAGRAPH')}{' '}
				<a href="mailto:info@travel.mp">
					{' '}
					{text('PROHIBITED_USES_SIXTEEN_THREE_BULLET_LINK')}.
				</a>
			</div>

			<h1 className="text-base text-left font-bold py-4">
				{text('PRIVACY_NOTICE_HEADER')}
			</h1>

			<div className="text-justify">
				<span>{text('PRIVACY_NOTICE_FIRST_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>{text('PRIVACY_NOTICE_SECOND_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>{text('PRIVACY_NOTICE_THIRD_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>{text('PRIVACY_NOTICE_FOURTH_PARAGRAPH')}</span>
			</div>

			<div>
				<strong>
					<span>{text('INFORMATION_WE_COLLECT')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('INFORMATION_WE_COLLECT_FIRST_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>{text('INFORMATION_WE_COLLECT_SECOND_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>{text('INFORMATION_WE_COLLECT_THIRD_PARAGRAPH')}</span>
			</div>
			{/* <div className="text-justify">
        <ul className="ml-6 my-1 list-disc">
          <li>{text('INFORMATION_WE_COLLECT_FIRST_BULLET')}</li>
          <li>{text('INFORMATION_WE_COLLECT_SECOND_BULLET')}</li>
          <li>{text('INFORMATION_WE_COLLECT_THIRD_BULLET')}</li>
        </ul>
      </div> */}

			<div>
				<strong>
					<span>{text('CONFIDENTIALITY')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('CONFIDENTIALITY_FIRST_PARAGRAPH')}</span>
			</div>

			<div>
				<strong>
					<span>{text('CHILDRENS_PRIVACY')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('CHILDRENS_PRIVACY_PARAGRAPH')}</span>
			</div>

			<div>
				<strong>
					<span>{text('COLLECT_YOUR_INFORMATION')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('COLLECT_YOUR_INFORMATION_FIRST_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>
					<ul className="ml-2 my-1 list-disc">
						<li aria-level="1">
							{text('COLLECT_YOUR_INFORMATION_FIRST_BULLET')}
						</li>
						<li aria-level="1">
							{text('COLLECT_YOUR_INFORMATION_SECOND_BULLET')}
						</li>
						<li aria-level="1">
							{text('COLLECT_YOUR_INFORMATION_THIRD_BULLET')}
						</li>
						<li aria-level="1">
							{text('COLLECT_YOUR_INFORMATION_FOURTH_BULLET')}
						</li>
						<li aria-level="1">
							{text('COLLECT_YOUR_INFORMATION_FIFTH_BULLET')}
						</li>
					</ul>
				</span>
				<span>{text('COLLECT_YOUR_INFORMATION_SECOND_PARAGRAPH')}</span>
			</div>

			<div className="text-justify">
				<span className="font-bold">
					{text('USE_SHARE_RETAIN_INFORMATION')}
				</span>
			</div>
			<div className="font-bold underline">
				<span>{text('USE_SHARE_RETAIN_INFORMATION_USE')}</span>
			</div>
			<div className="text-justify">
				<span>{text('USE_SHARE_RETAIN_INFORMATION_FIRST_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>{text('USE_SHARE_RETAIN_INFORMATION_FIRST_TWO_PARAGRAPH')}</span>
			</div>

			{/* <div className="text-justify">
        <span className="underline">
          {text('USE_SHARE_RETAIN_INFORMATION_SECOND_PARAGRAPH')}
        </span>
      </div> */}

			{/* <div className="text-justify">
        <span>{text('USE_SHARE_RETAIN_INFORMATION_THIRD_PARAGRAPH')}</span>
      </div> */}

			<div className="text-justify">
				<span>
					<ul className="ml-6 my-1 list-disc">
						<li aria-level="1">
							{text('USE_SHARE_RETAIN_INFORMATION_TWO_FIRST_BULLET')}
						</li>
						<li aria-level="1">
							{text('USE_SHARE_RETAIN_INFORMATION_TWO_SECOND_BULLET')}
						</li>
						<li aria-level="1">
							{text('USE_SHARE_RETAIN_INFORMATION_TWO_THIRD_BULLET')}
						</li>
						{/* <li aria-level="1">
              {text('USE_SHARE_RETAIN_INFORMATION_TWO_FOURTH_BULLET')}
            </li>
            <li aria-level="1">
              {text('USE_SHARE_RETAIN_INFORMATION_TWO_FIFTH_BULLET')}
            </li> */}
						<li aria-level="1">
							{text('USE_SHARE_RETAIN_INFORMATION_TWO_SIXTH_BULLET')}
						</li>
						<li aria-level="1">
							{text('USE_SHARE_RETAIN_INFORMATION_TWO_SEVENTH_BULLET')}
						</li>
					</ul>
				</span>

				{/* <div className="text-justify">
          <span className="underline">
            {text('USE_SHARE_RETAIN_INFORMATION_SECOND_PARAGRAPH')}
          </span>
        </div>
        <span>{text('USE_SHARE_RETAIN_INFORMATION_SECOND_TWO_PARAGRAPH')}</span> */}
			</div>
			{/* <div className="text-justify">
        <span>
          <ul className="ml-2 my-1 list-disc">
            <li aria-level="1">
              {text(
                'USE_SHARE_RETAIN_INFORMATION_CONTACT_TRACING_FIRST_BULLET'
              )}
            </li>
            <li aria-level="1">
              {text(
                'USE_SHARE_RETAIN_INFORMATION_CONTACT_TRACING_SECOND_BULLET'
              )}
            </li>
            <li aria-level="1">
              {text(
                'USE_SHARE_RETAIN_INFORMATION_CONTACT_TRACING_THIRD_BULLET'
              )}
            </li>
            <li aria-level="1">
              {text(
                'USE_SHARE_RETAIN_INFORMATION_CONTACT_TRACING_FOURTH_BULLET'
              )}
            </li>
          </ul>
        </span>
      </div> */}
			<div className="text-justify">
				<span className="underline">
					{text('USE_SHARE_RETAIN_INFORMATION_FOURTH_PARAGRAPH')}
				</span>
			</div>
			<span>{text('USE_SHARE_RETAIN_INFORMATION_FIFTH_PARAGRAPH')}</span>
			<div className="text-justify">
				<strong>
					<span>{text('RETENTION')}</span>
				</strong>
			</div>

			<div className="text-justify">
				<span>
					<ul className="ml-2 my-1 list-disc">
						{/* <li aria-level="1">{text('RETENTION_FIRST_BULLET')}</li>
            <ul className="ml-4 my-1" style={{ listStyleType: 'circle' }}>
              <li aria-level="2">{text('RETENTION_SECOND_BULLET')}</li>
              <li aria-level="2">{text('RETENTION_THIRD_BULLET')}</li>
            </ul> */}
						<li aria-level="1">{text('RETENTION_FOURTH_BULLET')}</li>
						<li aria-level="1">{text('RETENTION_FIFTH_BULLET')}</li>
					</ul>
				</span>
			</div>
			<div className="text-justify">
				<strong>
					<span>{text('DESTRUCTION')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('DESTRUCTION_FIRST_PARAGRAPH')}</span>
			</div>
			<div className="text-justify">
				<span>
					<ul className="ml-2 my-1 list-disc">
						<li aria-level="1">{text('DESTRUCTION_FIRST_BULLET')}</li>
						<li aria-level="1">{text('DESTRUCTION_SECOND_BULLET')}</li>
						<li aria-level="1">{text('DESTRUCTION_THIRD_BULLET')}</li>
					</ul>
				</span>
			</div>

			<div className="text-justify">
				<span>
					<a href="/" onClick={handleDelete} className="pointer-events-auto">
						{text('DELETE_ACCOUNT')}
					</a>
				</span>
			</div>

			<div className="text-justify">
				<strong>
					<span>{text('COOKIES')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('COOKIES_PARAGRAPH')}</span>
			</div>

			<div className="text-justify">
				<strong>
					<span>{text('SECURE_YOUR_INFORMATION')}</span>
				</strong>
			</div>
			<div className="text-justify">
				<span>{text('SECURE_YOUR_INFORMATION_PARAGRAPH')}</span>
			</div>
		</div>
	);
}

DataPrivacy.propTypes = {};

DataPrivacy.defaultProps = {};

export default DataPrivacy;
