import React from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

function OAuthPage() {
  const location = useLocation();
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    const query = qs.parse(location.search);
    try {
      if (!query?.token) {
        window.opener.postMessage(
          JSON.stringify({
            error: true,
            message: 'Invalid authentication link.',
          }),
          '*'
        );

        window.close();
        return;
      }
      window.opener.postMessage(
        JSON.stringify({
          token: query?.token,
        }),
        '*'
      );
      window.close();
    } catch (err) {
      setError(true);
    }
  }, [location]);
  return <div>{error ? 'Invalid url.' : 'Authenticating...please wait'}</div>;
}

OAuthPage.propTypes = {};

export default OAuthPage;
