import { useEffect, useCallback } from 'react';
import { useApiGet, useApiLoading } from 'react-reqq-lite';
import { logbookQr, submitLogbook } from './actions';
import { LOGBOOK_QR, SUBMIT_LOGBOOK } from './constants';

export const useLogbookQr = (uuid) => {
  const isLoading = useApiLoading(LOGBOOK_QR, 'get');
  const data = useApiGet(LOGBOOK_QR, {});
  useEffect(() => {
    logbookQr(uuid);
  }, [uuid]);
  return [isLoading, data];
};

export const useSubmitGuestLogbook = () => {
  const isLoading = useApiLoading(SUBMIT_LOGBOOK, 'post');
  const submit = useCallback((payload, callback) => {
    submitLogbook(payload, callback);
  }, []);
  return [isLoading, submit];
};
