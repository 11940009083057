import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-reqq-lite';
import { Router } from 'react-router-dom';
import App from 'App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as serviceWorker from 'serviceWorker';
import store from 'setup/store';
import history from 'setup/history';
import 'react-phone-input-2/lib/plain.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'assets/tailwind/style.css';

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <App />
      </Router>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const setState = (state) =>
  store.dispatch({
    type: 'API/v2/got_set',
    key: 'PWA_STATE',
    data: state,
  });

serviceWorker.register({
  onSuccess: () => {
    setState({ state: 'ONLINE' });
  },
  onUpdate: (reg) => {
    setState({ state: 'UPDATED', reg });
  },
  onOffline: () => {
    setState({ state: 'OFFLINE' });
  },
});
