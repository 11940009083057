import React from 'react';
import logo from 'assets/images/logo.svg';
import QRCodeCanvas from 'qrcode.react';
import { useParams } from 'react-router-dom';
// import PropTypes from 'prop-types';

function PrintRoute() {
	const { uuid, name, size } = useParams();
	return (
		<div className="bg-white flex flex-col items-center justify-center gap-2">
			<img src={logo} alt="logo" className="mx-auto w-full" />
			<div className="p-2 bg-white drop-shadow-xl w-full">
				{uuid && (
					<QRCodeCanvas
						value={uuid || ''}
						size={parseInt(size, 10)}
						className="mx-auto w-full"
					/>
				)}
				{name && (
					<p className="text-primary-500 font-semibold text-base text-center my-1">
						{name}
					</p>
				)}
			</div>
		</div>
	);
}

PrintRoute.propTypes = {};

export default PrintRoute;
