import React from 'react';
import { Link } from 'react-router-dom';

import { useLanguage } from 'modules/common/components/Language';

function Page404() {
  const [text] = useLanguage();
  return (
    <div className="text-center">
      <div>
        <h1 className="text-4xl font-bold">404</h1>
      </div>
      <div className="mb-3">
        The page that you are trying
        <br /> to access does not exists
      </div>
      <div>
        <Link to="/">{text('BACK_TO_HOME_PAGE')}</Link>
      </div>
    </div>
  );
}

Page404.propTypes = {};

export default Page404;
