/* global google */
import React from 'react';
import { countryOptions } from 'modules/constants/countries';
import { useLocalization } from 'modules/hooks/localization';

export const loadAPI = (url, onLoad, onReject) => {
  try {
    const tag = document.createElement('script');
    tag.type = 'text/javascript';
    tag.async = false;

    const handleLoad = () => {
      if (typeof onLoad === 'function') onLoad();
    };
    const handleReject = () => {
      if (typeof onReject === 'function') onReject();
    };

    tag.addEventListener('load', handleLoad);
    tag.addEventListener('error', handleReject);
    tag.src = url;
    document.getElementsByTagName('body')[0].appendChild(tag);
  } catch (err) {
    console.error(err); // eslint-disable-line
    onReject(err);
  }
};

let isPageTranslated = false;

export const loadGoogleTranslator = (
  locale,
  id = 'google-translate-element'
) => {
  window.googleTranslateElementInit = () => {
    if (['EN', 'JP', 'KR', 'CN'].indexOf(locale) > -1 || !locale) return;
    const pageLanguage = locale.toLowerCase();
    // eslint-disable-next-line
    // new google.translate.TranslateElement({
    //   pageLanguage,
    //   includedLanguages: pageLanguage,
    //   layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
    //   autoDisplay: false
    // }, id);
    // eslint-disable-next-line
    new google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        includedLanguages: pageLanguage,
        autoDisplay: false,
      },
      id
    );
    const a = document.querySelector(`#${id} select`);
    a.selectedIndex = 1;
    a.dispatchEvent(new Event('change'));
    isPageTranslated = true;
  };
  loadAPI(
    'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
  );
};

function LocaleSwitcher_DISABLED() {
  const [locale, setLocale] = useLocalization();
  const handleChange = ({ target }) => {
    setLocale(target.value);
    window.location.reload();
  };
  React.useEffect(() => {
    if (isPageTranslated) return;
    loadGoogleTranslator(locale);
  }, [locale]);
  return (
    <>
      <select
        className="rounded border px-2 py-0 text-xs w-full notranslate skiptranslate"
        onChange={handleChange}
        value={locale}
      >
        <option value="">Auto detect</option>
        {countryOptions.map((x) => (
          <option key={x.value} value={x.value}>
            {x.label}
          </option>
        ))}
      </select>
      <div
        className="absolute opacity-0 pointer-events-none"
        id="google-translate-element"
      />
    </>
  );
}

LocaleSwitcher_DISABLED.propTypes = {};

export { LocaleSwitcher_DISABLED };

const LocaleSwitcher = () => null;
// export default LocaleSwitcher;
export default LocaleSwitcher;
