import React from 'react';
import { useLanguage } from 'modules/common/components/Language';

function Footer() {
	const [text] = useLanguage();
	return (
		<>
			<div className="bg-secondary-200 py-2">
				<div className="container mx-auto text-xs">
					<div className="flex flex-col md:flex-row gap-2 items-center text-center justify-between ">
						<div>
							&copy; {text('FOOTER_YEAR_NOTE')} v2.0.17.{' '}
							{text('FOOTER_COPYRIGHT')}
						</div>
						<div>{text('OWNED_BY')} CNMI Government</div>
						<div>
							Powered by{' '}
							<a
								className="text-blue-400"
								target="_blank"
								rel="noreferrer"
								href="https://apsolutions.mp/"
							>
								{' '}
								AP Solutions
							</a>
						</div>
						<div className="capitalize">
							{text('CONTACT_US')}:
							<a className="hover:underline font-normal ml-1" href="/support">
								{text('PROHIBITED_USES_SIXTEEN_THREE_BULLET_LINK')}
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

Footer.propTypes = {};

export default Footer;
