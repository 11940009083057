import React from 'react';

function Public() {
	return (
		<div className="flex h-full overflow-hidden bg-main-hero">
			<div className="flex-grow m-auto">
				<div className="h-screen flex items-center justify-center text-xl font-medium">
					503 | Under Maintenance
				</div>
				<div className="flex text-sm items-center justify-center font-normal">
					We will be back soon
				</div>
			</div>
		</div>
	);
}

Public.propTypes = {};

export default Public;
