import React from 'react';
import PropTypes from 'prop-types'
import { usePwaState, useIsConnected } from './hooks';

const AutoUpdateBanner = ({ onUpdate }) => {
  const [countDown, setCountDown] = React.useState(3);
  React.useEffect(() => {
    if (countDown < 1) {
      onUpdate();
      return;
    }
    setTimeout(() => {
      setCountDown(countDown - 1);
    }, 1000);
    // eslint-disable-next-line
  }, [countDown, setCountDown]);
  if (countDown < 1) return null;
  return (
    <div className="fixed top-0 left-0 w-full bg-yellow-500 shadow py-1 text-center z-50">
      <span
        className="text-white text-underline font-light"
      >
        There are new updates, the page will reload in {countDown}.
      </span>
    </div>
  )
};

AutoUpdateBanner.propTypes = {
  onUpdate: PropTypes.func.isRequired
}

function PWAState() {
  const [state, reg] = usePwaState();
  const [isOnline] = useIsConnected();

  const handleUpdate = () => {
    if (!reg) return;
    const registrationWaiting = reg?.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
      registrationWaiting.addEventListener('statechange', ({ target }) => {
        if (target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };
  if (state === 'UPDATED') {
    return (
      <AutoUpdateBanner onUpdate={handleUpdate} />
    );
  }
  if (state === 'OFFLINE' || !isOnline) {
    return (
      <div className="fixed top-0 left-0 w-full bg-red-500 shadow py-1 text-center z-50 text-white">
        Offline Mode
      </div>
    );
  }
  return null;
}

PWAState.propTypes = {};

export default PWAState;
