import { toastSuccess } from 'react-geek-toast';
import { req } from 'react-reqq-lite';
import { persistStorage } from 'modules/common/helpers';
import { getProfile } from 'modules/digital-logbook/actions';
import * as c from './constants';
import { transformSettings } from './transformers';

const transformVisitHistory = (raw) => {
  const is_domestic = +raw?.attributes?.is_domestic === 1;
  const customs_status = raw?.attributes?.customs_status;
  const health_declaration_status = raw?.attributes?.health_declaration_status;
  const survey_status = raw?.attributes?.survey_status;
  const attestation_status = raw?.attributes?.attestation_status;

  const is_completed = is_domestic
    ? survey_status === 'SUBMITTED' && customs_status === 'SUBMITTED'
    : customs_status === 'SUBMITTED' &&
      (health_declaration_status === 'SUBMITTED' ||
        attestation_status === 'SUBMITTED') &&
      survey_status === 'SUBMITTED';
  return {
    id: raw?.id,
    is_domestic,
    customs_form_uuid: raw?.attributes?.customs_form_uuid,
    customs_status,
    expires_at: raw?.attributes?.expires_at,
    is_expired: raw?.attributes?.is_expired,
    date_of_arrival: raw?.attributes?.date_of_arrival,
    attestation_form_uuid: raw?.attributes?.attestation_form_uuid,
    attestation_status,
    health_declaration_form_uuid: raw?.attributes?.health_declaration_form_uuid,
    health_declaration_status,
    survey_form_uuid: raw?.attributes?.survey_form_uuid,
    survey_status,
    special_survey_created_at: raw?.attributes?.special_survey_created_at,
    destination: raw?.attributes?.destination,
    origin: raw?.attributes?.origin,
    origin_country: raw?.attributes?.origin_country,
    is_completed,
    updated_at: raw?.attributes?.updated_at,
    created_at: raw?.attributes?.created_at,
  };
};

const payloadVisit = (payload) => ({
  ...payload,
  is_domestic: payload.is_domestic ? 1 : 0,
});

export const guestTripToken = (payload, onSuccess) => {
  req.post({
    key: c.GUEST_TRIP_TOKEN,
    url: '/api/v1/guest/trip-token',
    payload,
    onSuccess: (res) => {
      if (onSuccess) onSuccess(res.response);
    },
  });
};

export const getVisitHistory = (params, onSuccess) => {
  req.get({
    key: c.GET_VISIT_HISTORY,
    url: `/api/v1/visit-histories`,
    params,
    transform: (res) => (res.data || []).map(transformVisitHistory),
    onSuccess: (res) => {
      if (onSuccess)
        onSuccess((res?.response?.data || []).map(transformVisitHistory));
    },
  });
};

export const getVisitById = (id) => {
  req.get({
    key: c.SELECT_VISIT,
    url: `/api/v1/visit-histories/${id}`,
    transform: (res) => transformVisitHistory(res.data),
  });
};

export const createVisitHistory = (payload, onSuccess) => {
  req.post({
    key: c.CREATE_VISIT_HISTORY,
    url: '/api/v1/visit-histories',
    payload: payloadVisit(payload),
    onSuccess: (res) => {
      toastSuccess(res?.response?.message);
      if (onSuccess) onSuccess(res?.response?.uuid);
    },
  });
};

export const updateVisitHistory = (id, payload, onSuccess, onError) => {
  req.put({
    key: c.UPDATE_VISIT_HISTORY,
    url: `/api/v1/visit-histories/${id}`,
    payload,
    onSuccess: () => {
      // toastSuccess(res?.response?.message);
      if (onSuccess) onSuccess(id);
    },
    onError,
  });
};

export const deleteVisitHistory = (id, onSuccess, onError) => {
  req.remove({
    key: c.DELETE_VISIT_HISTORY,
    url: `/api/v1/visit-histories/${id}`,
    onSuccess: (res) => {
      toastSuccess(res?.response?.message || 'Successfully deleted trip');
      if (onSuccess) onSuccess();
    },
    onError,
  });
};

export const getAllModuleSettings = () => {
  req.get({
    key: c.GET_ALL_MODULE_SETTINGS,
    url: '/api/v1/settings',
    transform: (res) =>
      (res.data || [])
        .map(transformSettings)
        .filter((item) => item?.typed_value === true),
  });
};

export const moduleSettings = (code) => {
  req.get({
    key: c.MODULE_SETTINGS,
    url: `/api/v1/settings/${code}`,
  });
};

export const authGuest = (payload, onSuccess) => {
  req.post({
    key: c.AUTH_GUEST,
    url: '/api/v1/guest/login',
    payload,
    onSuccess: (res) => {
      const token = res?.response?.token;
      sessionStorage.removeItem('auth');
      persistStorage.set('is_guest', 1);
      if (token) {
        persistStorage.set('token', token);
        getProfile();
      }
      if (onSuccess) onSuccess(res);
    },
  });
};

export const deleteProfile = (onSuccess) => {
  req.remove({
    key: c.DELETE_PROFILE,
    url: `/api/v1/guest/profile`,
    onSuccess: (res) => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/app';
      toastSuccess(res?.message || 'Profile Successfully Deleted!');
      if (onSuccess) onSuccess();
    },
  });
};
