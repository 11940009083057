import { req } from 'react-reqq-lite';
import { each, get } from 'lodash';
import moment from 'moment';
import { toastError, toastSuccess, toastWarning } from 'react-geek-toast';
import { persistStorage } from 'modules/common/helpers';
import history from 'setup/history';
import {
  transformProfile,
  payloadProfile,
  payloadSymptoms,
  transformScanResult,
  transformVisit,
  transformScannerScan,
} from './transformers';
import * as c from './constants';

export const deleteAccount = (onSuccess) => {
  req.remove({
    key: c.DELETE_ACCOUNT,
    url: '/api/v2/me/profile',
    onSuccess,
    onError: () => {
      toastError(
        'Unable to delete account. Please contact us on help@safetravelsmarianas.com'
      );
    },
  });
};

export const getProfile = () => {
  req.get({
    key: c.PROFILE,
    url: '/api/v1/me/profile',
    transform: (res) => {
      const data = transformProfile(
        res?.response?.data || {},
        res?.response?.included || []
      );
      persistStorage.set('my-profile', data);
      return {
        has_profile: true,
        data,
        token: localStorage.getItem('token'),
      };
    },
    onSuccess: (res) => {
      const { data } = res?.response;
      const transformData = transformProfile(
        res?.response?.data || {},
        res?.response?.included || []
      );
      persistStorage.set('my-profile', transformData);
      if (!data?.attributes?.is_reg_completed && !data?.attributes?.is_guest) {
        toastWarning('Please complete your profile!');
        history.push('/app/profile');
      }
    },
    onError: () => {
      const data = persistStorage.get('my-profile');
      req.set(c.PROFILE, {
        use_cached: true,
        has_profile: false,
        data,
        token: null,
      });
    },
  });
};

export const generateFcmToken = (payload) => {
  req.post({
    key: c.GENERATE_TOKEN,
    url: '/api/v1/registrant-set-fcm-token',
    payload,
  });
};

export const updateProfile = (payload, onSuccess) => {
  req.post({
    key: c.PROFILE,
    url: '/api/v2/me/logbook',
    payload: payloadProfile(payload),
    onSuccess: (res) => {
      toastSuccess(res?.message || 'Saved!');
      getProfile();
      if (onSuccess) onSuccess();
    },
  });
};

export const updateSymptoms = (payload, onSuccess, onError) => {
  req.put({
    key: c.SYMPTOMS,
    url: '/api/v2/me/families',
    payload: payloadSymptoms(payload),
    onSuccess: (res) => {
      toastSuccess(res?.message || 'Saved!');
      getProfile();
      if (onSuccess) onSuccess();
    },
    onError,
  });
};

export const authByOtp = (payload, onSuccess) => {
  sessionStorage.removeItem('otp-attempt');
  req.post({
    key: c.AUTH_BY_OTP,
    url: '/api/v1/login/request-otp',
    payload,
    onSuccess: (res) => {
      const newT = moment().toISOString();
      sessionStorage.setItem('otp-last-sent', newT);
      if (onSuccess) onSuccess(res);
    },
  });
};

export const resendOtpViaSms = (payload, onSuccess) => {
  req.post({
    key: c.AUTH_BY_OTP,
    url: '/api/v1/login/request-otp-via-sms',
    payload,
    onSuccess: (res) => {
      // const newT = moment().toISOString();
      // sessionStorage.setItem('otp-last-sent', newT);
      if (onSuccess) onSuccess(res);
    },
  });
};

export const authByOtpEmail = (payload, onSuccess) => {
  sessionStorage.removeItem('otp-attempt');
  req.post({
    key: c.AUTH_BY_OTP,
    url: '/api/v2/login/request-otp-via-email',
    payload,
    onSuccess: (res) => {
      const newT = moment().toISOString();
      sessionStorage.setItem('otp-last-sent', newT);
      if (onSuccess) onSuccess(res);
    },
  });
};

export const otpViaEmail = (payload, onSuccess, onError) => {
  // onSuccess('alpha@yopmail.com');
  // if (true) return;
  sessionStorage.removeItem('otp-attempt');
  req.post({
    key: c.AUTH_BY_OTP,
    url: '/api/v1/login/request-otp-via-email',
    payload,
    onSuccess: (res) => {
      if (onSuccess) onSuccess(res?.response?.masked_email);
    },
    onError: (res) => {
      if (res?.status === 404) {
        onSuccess(null);
        return;
      }
      onError(null);
    },
  });
};

export const setEmailForOTP = (payload, onSuccess) => {
  req.post({
    key: c.AUTH_BY_OTP,
    url: '/api/v1/login/verify-email-via-number',
    payload,
    onSuccess,
  });
};

export const verifyOtp = (payload, onSuccess) => {
  req.post({
    key: c.AUTH_BY_OTP,
    url: '/api/v1/login/confirm-otp',
    payload,
    onSuccess: (res) => {
      if (payload?.email) {
        sessionStorage.setItem('email', payload?.email);
      }
      const token = res?.response?.token;
      sessionStorage.removeItem('auth');
      sessionStorage.removeItem('otp-last-sent');
      req.set(c.AUTH_BY_OTP, true);
      if (token) {
        persistStorage.set('token', token);
        getProfile();
      }
      if (onSuccess) onSuccess(res);
    },
  });
};

export const listPurposeOptions = () => {
  req.get({
    key: c.PURPOSE_OPTIONS,
    url: '/api/v1/purpose',
    transform: (res) => {
      const newOptions = [];
      each(res, (item) => {
        each(item, (v) => {
          newOptions.push({
            label: v,
            value: v,
          });
        });
      });
      return newOptions;
    },
    cache: true,
  });
};

export const listSymptoms = () => {
  req.get({
    key: c.SYMPTOMS_OPTIONS,
    url: '/api/v1/symptoms',
    transform: (res) => {
      const common = [];
      const other = [];
      const newList = (res.data || []).map((row) => {
        const newRow = {
          label: get(row, 'attributes.title'),
          value: get(row, 'attributes.code'),
          data: get(row, 'attributes') || {},
        };
        if (row.attributes.category === 'COMMON') common.push(newRow);
        if (row.attributes.category === 'OTHER') other.push(newRow);
        return newRow;
      });
      req.set(`${c.SYMPTOMS_OPTIONS}/common`, common);
      req.set(`${c.SYMPTOMS_OPTIONS}/other`, other);
      return newList;
    },
    // cache: true,
  });
};

export const scanQr = (uuid) => {
  req.post({
    key: c.SCAN_RESULT,
    url: '/api/v1/me/profile/scan_qr',
    payload: { uuid },
    onSuccess: (res) => {
      req.set(c.SCAN_RESULT, transformScanResult(uuid, res?.response || {}));
    },
    onError: (res) => {
      req.set(c.SCAN_RESULT, transformScanResult(uuid, res.response || {}));
    },
  });
};

export const confirmVisit = (uuid) => {
  req.post({
    key: c.CONFIRM_VISIT,
    url: '/api/v1/me/profile/scan_qr/confirm',
    payload: { uuid },
    onSuccess: (res) => {
      req.set(c.CONFIRM_VISIT, transformVisit(res || {}));
    },
    onError: (res) => {
      req.set(c.CONFIRM_VISIT, transformVisit(res || {}));
    },
  });
};

export const establishmentScannerScanQr = (payload) => {
  req.post({
    key: c.ACCOUNT_SCANNER_RESULT,
    url: '/api/v1/me/profile/scan_qr/front-liner',
    payload,
    onSuccess: (res) => {
      req.set(
        c.ACCOUNT_SCANNER_RESULT,
        transformScannerScan(payload.uuid, res?.response || {})
      );
    },
    onError: (res) => {
      req.set(
        c.ACCOUNT_SCANNER_RESULT,
        transformScannerScan(payload.uuid, res.response || {})
      );
    },
  });
};
