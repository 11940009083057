import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import placeholder from 'assets/images/logo-icon.svg';
import { showAlert } from 'modules/common/components/Modal';
import { SymptomsForm } from 'modules/digital-logbook/containers/LogbookSymptoms';
import { toastWarning } from 'react-geek-toast';
import ProfileForm from './ProfileForm';
import { useSubmitGuestLogbook } from './hooks';

const INIT_FORM = {
  name: '',
  middle_name: '',
  last_name: '',
  // birth_date: '',
  age: '',
  gender: '',
  email: '',
  mobile_number: '',
  company_name: '',
  region_code: '',
  province_code: '',
  municipality_code: '',
  barangay_code: '',
  purpose: '',

  health_checklist: [],
  health_checklist_other: {},
};

function GuestLogbookForm({ qrData, onSuccess }) {
  const [step, setStep] = React.useState(0);
  const [form, setForm] = React.useState(INIT_FORM);
  const [symptoms, setSymptoms] = React.useState(null);
  const [isLoading, submit] = useSubmitGuestLogbook();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (step < 1) {
      setStep(step + 1);
      return;
    }
    if (symptoms === null) {
      toastWarning('Please select atleast 1');
      return;
    }
    const payload = {
      ...form,
      symptoms: isEmpty(symptoms) ? ['NONE_OF_THE_ABOVE'] : symptoms,
      location_qr_code_uuid: qrData.id,
    };
    submit(payload, () => {
      setStep(2);
      onSuccess();
    });
  };
  const handleClear = () => {
    if (step < 1) {
      showAlert({
        content: 'Are you sure you want to clear?',
        onYesLabel: 'Cancel',
        onNoLabel: 'Clear',
        onNo: (close) => {
          close();
          setForm(INIT_FORM);
          setSymptoms(null);
        },
      });
      return;
    }
    setStep(step - 1);
  };
  if (step === 2) {
    return (
      <>
        <div className="text-center">
          <div className="flex justify-center">
            <img
              className="h-32 w-32 object-contain"
              src={qrData.logo || placeholder}
              alt={qrData.name}
            />
          </div>
          <div className="mb-4">
            <div className="text-2xl font-bold">{qrData.name}</div>
            <div className="text-lg">{qrData.branch}</div>
          </div>
        </div>
        <div className="border-y py-4 space-y-3 text-center">
          <p>
            Thank you for using Travel Marianas Digital Logbook! Please always
            wear your mask, wash your hands and maintain social distancing.
          </p>
          <p>
            Don&apos;t forget to download Travel Marianas App for digital
            contact tracing to protect yourself and your loved ones. Thank you!
          </p>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="flex items-center border-b pb-2 mb-2">
        <div className="float-left mr-2">
          <img
            className="h-12 w-12"
            src={qrData.logo || placeholder}
            alt={qrData.name}
          />
        </div>
        <div className="leading-none">
          <div className="font-bold text-lg">{qrData.name}</div>
          <div className="text-gray-800">{qrData.branch}</div>
        </div>
      </div>
      <form className="flex flex-grow flex-col" onSubmit={handleSubmit}>
        <div className="flex flex-col flex-grow relative overflow-y-auto">
          <div className="absolute h-full w-full flex flex-col">
            {step === 0 && <ProfileForm form={form} setForm={setForm} />}
            {step === 1 && (
              <SymptomsForm form={symptoms} setForm={setSymptoms} />
            )}
          </div>
        </div>
        <div className="mt-auto py-2 grid grid-cols-2 gap-2">
          <button
            className="btn w-full light"
            type="button"
            onClick={handleClear}
          >
            {step < 1 ? 'Clear' : 'Back'}
          </button>
          <button
            className="btn w-full primary"
            type="submit"
            disabled={isLoading}
          >
            {step < 1 ? 'Next' : 'Submit'}
          </button>
        </div>
      </form>
    </>
  );
}

GuestLogbookForm.propTypes = {
  qrData: PropTypes.instanceOf(Object).isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default GuestLogbookForm;
