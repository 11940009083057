import { useState, useCallback, useEffect, useRef } from 'react';
import { debounce, forOwn, get, isEmpty } from 'lodash';
import moment from 'moment';
import uploadcare from 'uploadcare-widget';
import { toastError } from 'react-geek-toast';

export const transformIncluded = (x, included) => {
  if (!included || isEmpty(included)) return x;
  const rowIncluded = {};
  forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === get(z, 'type') && y.id === get(z, 'id')
            ) || {}
        )
      : included.find(
          (y) => y.type === get(v, 'data.type') && y.id === get(v, 'data.id')
        ) || {};
  });
  const { links, relationships, type, ...rest } = x;
  return { ...rest, included: rowIncluded };
};

export const parseNumber = (str, default_value = false) => {
  const v = parseFloat(`${str}`.replace(/,/g, ''));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== 'boolean' ? default_value : str;
  return v;
};

export const formatNumber = (v, decimal = 2) => {
  try {
    const n = parseNumber(v);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(n)) return v;
    return n.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  } catch (err) {
    return v;
  }
};

export const formatDate = (
  date,
  format = 'MM/DD/YYYY',
  default_value = '-'
) => {
  if (!date) return default_value;
  const d = new Date(date);
  if (d.toString() === 'Invalid Date') return default_value;
  return moment(d).format(format);
};

export const formatNumberAbbr = (num, decimal = 0) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(decimal).replace(rx, '$1') + si[i].symbol;
};

export const persistStorage = {
  get: (key, defaultValue) => {
    try {
      const obj = JSON.parse(localStorage.getItem(key));
      if (obj.type === 'object') return JSON.parse(obj.data);
      return obj.data;
    } catch (err) {
      return defaultValue;
    }
  },
  set: (key, newValue) => {
    const type = typeof newValue;
    const data = type === 'object' ? JSON.stringify(newValue) : newValue;
    localStorage.setItem(key, JSON.stringify({ type, data }));
  },
  remove: (key) => {
    localStorage.removeItem(key);
  },
};

export const storage = {
  get: (key) => {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return false;
    }
  },
  set: (key, value) => {
    const newValue = JSON.stringify(value);
    sessionStorage.setItem(key, newValue);
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

export const useLocalPersistState = (key, defaultValue) => {
  const [state, setState] = useState(persistStorage.get(key, defaultValue));
  const stateRef = useRef(state);
  const set = useCallback(
    (v) => {
      const value = typeof v === 'function' ? v(stateRef.current) : v;
      setState(value);
      persistStorage.set(key, value);
    },
    [key, setState]
  );
  useEffect(() => {
    stateRef.current = state;
  }, [state]);
  // useEffect(() => {
  //   return () => {
  //     persistStorage.remove(key)
  //   }
  // }, [key]);
  return [state, set];
};

export const usePersistState = (key, state) => {
  const [value, setValue] = useState(storage.get(key) || state);
  // eslint-disable-next-line
  const updateState = useCallback(
    debounce((newState) => {
      storage.set(key, newState);
    }, 500),
    []
  );
  useEffect(() => {
    updateState(value);
    return () => {
      storage.remove(key);
    };
  }, [value]); // eslint-disable-line
  return [value, setValue];
};

function maxFileSize(size) {
  return function throwToast(fileInfo) {
    // eslint-disable-line
    if (fileInfo.size !== null && fileInfo.size > size) {
      toastError('File size is too large');
      throw new Error('fileMaximumSize');
    }
  };
}

export const uploadPhoto = (options) =>
  new Promise((resolve) => {
    const opt = {
      tabs: ['file', 'url', 'camera'],
      ...options,
    };

    uploadcare
      .openDialog(null, {
        publicKey: process.env.REACT_APP_UPLOADCARE_KEY || 'demopublickey',
        validators: [maxFileSize(2 * 1024 * 1024)],
        ...opt,
        imagesOnly: true,
      })
      .done((file) => {
        file.done((x) => {
          const conCat = `${x.name}::${x.cdnUrl}`;
          return resolve(conCat);
        });
      })
      .fail(() => {
        resolve(null);
      });
  });
