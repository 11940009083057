import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { useLanguage } from 'modules/common/components/Language';
import { toastWarning } from 'react-geek-toast';
import Header from '../components/Header';
import { useSaveSymptoms, useSymptomOptions } from '../hooks';

function LogbookSymptoms() {
  const history = useHistory();
  const [pending, setPending] = useState(false);
  const [form, setForm] = React.useState(null);

  const [isSaving, submit] = useSaveSymptoms();
  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);
    if (form === null) {
      setPending(false);
      toastWarning('Please select atleast 1');
      return;
    }
    const payload = {
      status: isEmpty(form) ? ['NONE_OF_THE_ABOVE'] : form,
    };
    submit(
      payload,
      () => {
        setPending(false);
        history.push('/digital-logbook');
      },
      () => setPending(false)
    );
  };
  return (
    <>
      <Header label="Symptoms" returnUrl="/digital-logbook" returnLabel="" />
      <div className="bg-white h-full rounded-t-3xl p-5 flex flex-col rounded-t-effect relative mt-3 rounded-t-effect z-10 flex-grow">
        <form className="flex flex-grow flex-col" onSubmit={handleSubmit}>
          <SymptomsForm setForm={setForm} form={form} />
          <div className="mb-2">
            <button
              className="btn w-full primary"
              type="submit"
              disabled={isSaving || pending}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

LogbookSymptoms.propTypes = {};

function SymptomsForm({ form, setForm }) {
  const [text] = useLanguage();
  const [isLoading, list] = useSymptomOptions();
  const handleSelect = (row) => (e) => {
    e.preventDefault();
    setForm((state) => {
      if (!state) return [row.value];
      return state.indexOf(row.value) > -1
        ? state.filter((item) => item !== row.value)
        : state.concat([row.value]);
    });
  };
  const handleSelectNone = (e) => {
    e.preventDefault();
    setForm([]);
  };
  return (
    <>
      <div
        className="flex flex-col flex-grow relative overflow-y-auto"
        style={{ height: '100px' }}
      >
        <div className="absolute h-full w-full grid gap-2">
          <p className="text-sm text-center font-semibold">
            Do you experience any of the following?
          </p>
          {list.length < 1 ? (
            <div className="text-center">
              {isLoading ? 'Loading...' : 'There are no symptoms.'}
            </div>
          ) : (
            list.map((item) => (
              <RowItem
                key={item.value}
                data={item}
                onClick={handleSelect}
                isChecked={Array.isArray(form) && form.indexOf(item.value) > -1}
              />
            ))
          )}
        </div>
      </div>
      <div className="py-2 border-t border-gray-200">
        <a
          href="/"
          className="flex items-center bg-primary-50/50 text-gray-900 p-2 border-b-2 rounded shadow-sm w-full relative"
          onClick={handleSelectNone}
        >
          <div className="ml-2">
            <p className="text-sm font-bold">{text('NONE_OF_THE_ABOVE')}</p>
          </div>
          <div className="h-8 w-8 ml-auto text-2xl">
            <input
              className="h-5 w-5 rounded-md bg-white text-primary-500 pointer-events-none"
              type="checkbox"
              onChange={() => {}}
              checked={Array.isArray(form) && form.length < 1}
            />
          </div>
        </a>
      </div>
    </>
  );
}

SymptomsForm.defaultProps = {
  form: null,
};

SymptomsForm.propTypes = {
  form: PropTypes.instanceOf(Object),
  setForm: PropTypes.func.isRequired,
};

function RowItem({ data, onClick, isChecked }) {
  return (
    <a
      href="/"
      onClick={onClick(data)}
      className="flex items-center bg-primary-50/50 border-b-2 p-2 rounded w-full text-gray-900"
    >
      <div className="h-10 w-10 bg-primary-200 rounded-lg overflow-hidden">
        <img className="object-fit" src={get(data, 'data.icon')} alt="Fever" />
      </div>
      <div className="ml-2">
        <p className="text-xs font-medium">{data.label}</p>
      </div>
      <div className="h-8 w-8 ml-auto text-2xl">
        <input
          className="h-5 w-5 rounded-full bg-white text-primary-500 pointer-events-none"
          type="checkbox"
          onChange={() => {}}
          checked={isChecked}
        />
      </div>
    </a>
  );
}

RowItem.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  onClick: PropTypes.func.isRequired,
};

export { SymptomsForm };

export default LogbookSymptoms;
