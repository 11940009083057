import { useState, useEffect } from 'react';
import { useApiGet } from 'react-reqq-lite';

export const usePwaState = () => {
  const pwa = useApiGet('PWA_STATE', {});
  return [pwa?.state, pwa?.reg];
};

export const useIsConnected = () => {
  const [isOnline, setIsOnline] = useState(window?.navigator?.onLine);
  useEffect(() => {
    const onOnline = () => {
      setIsOnline(true);
    };
    const onOffline = () => {
      setIsOnline(false);
    };
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);
    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);
  return [isOnline];
};
