import React from 'react';
import PropTypes from 'prop-types';
import { deleteProfile } from 'travel-modules/actions';
import { ModalClose } from '../components/Modal';

export const GuestReturnModal = ({ onClose, action }) => {
  return (
    <>
      <ModalClose onClose={onClose} />
      <div className="py-4 px-5">
        <div className="mb-2 text-center">
          Are you sure you want to exit in this form ? The progress you have
          made will be completely deleted
          <br />
        </div>
        <div className="flex">
          <button onClick={action} type="button" className="btn danger w-full">
            Confirm
          </button>
          <button
            className="btn light w-full ml-2"
            type="button"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};
GuestReturnModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};
function GuestReturn(onClose) {
  const directGuestLogout = () => {
    deleteProfile();
  };
  return (
    <>
      <GuestReturnModal onClose={onClose} action={directGuestLogout} />
    </>
  );
}
export default GuestReturn;
