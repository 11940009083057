import { createContext } from 'react';

export const CREATE_VISIT_HISTORY = 'APP/create_visit_history';
export const UPDATE_VISIT_HISTORY = 'APP/update_visit_history';
export const GET_VISIT_HISTORY = 'APP/get_visit_history';
export const DELETE_VISIT_HISTORY = 'APP/delete_visit_history';
export const SELECT_VISIT = 'APP/select_visit';
export const GET_ALL_MODULE_SETTINGS = 'APP/get_all_module_settings';
export const MODULE_SETTINGS = 'APP/module_settings';
export const AUTH_GUEST = 'AUTH/auth_guest';
export const DELETE_PROFILE = 'AUTH/delete_profile';
export const GUEST_TRIP_TOKEN = 'AUTH/guest_trip_token';

export const ProfileProviderContext = createContext();

export const AUTO_FILL_MAPPER = {
  'First Name': 'profile:first_name',
  'Middle Name': 'profile:middle_name',
  'Last Name': 'profile:last_name',
  Gender: 'profile:gender',
};
