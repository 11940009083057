import React, { useState, useCallback } from 'react';
import { toastInfo, toastWarning, toastSuccess } from 'react-geek-toast';
import { persistStorage, useLocalPersistState } from 'modules/common/helpers';
import { req, useApiGet, useApiLoading } from 'react-reqq-lite';
import { showModal } from 'modules/common/components/Modal';
import * as actions from './actions';
import * as c from './constants';
// eslint-disable-next-line import/no-cycle
import RegisterEmailForOTP from './modals/RegisterEmailForOTP';

export const useProfile = () => {
	const [profile] = useLocalPersistState('my-profile', null);
	return [profile];
};

const EMPTY = {};

export const useProfileData = () => {
	const profile = useApiGet(c.PROFILE, null);
	return [profile?.data || EMPTY];
};

export const useSymptomOptions = () => {
	const isLoading = useApiLoading(c.SYMPTOMS_OPTIONS, 'get');
	const list = useApiGet(c.SYMPTOMS_OPTIONS, []);
	React.useEffect(() => {
		actions.listSymptoms();
	}, []);
	return [isLoading, list];
};

export const useAuthByOtp = () => {
	const isLoading = useApiLoading(c.AUTH_BY_OTP, 'post');
	const submit = React.useCallback((payload, onSuccess) => {
		actions.authByOtp(payload, onSuccess);
	}, []);
	return [isLoading, submit];
};

export const useAuthByOtpEmail = () => {
	const isLoading = useApiLoading(c.AUTH_BY_OTP, 'post');
	const submit = React.useCallback((payload, onSuccess) => {
		actions.authByOtpEmail(payload, onSuccess);
	}, []);
	return [isLoading, submit];
};

export const useVerifyOtp = () => {
	const isLoading = useApiLoading(c.AUTH_BY_OTP, 'post');
	const isAuthenticating = useApiGet(c.AUTH_BY_OTP, false);
	const submit = React.useCallback((payload, onSuccess) => {
		actions.verifyOtp(payload, onSuccess);
		return () => {
			req.set(c.AUTH_BY_OTP, false);
		};
	}, []);
	return [isLoading || isAuthenticating, submit];
};

export const useOtpViaEmail = () => {
	const isLoading = useApiLoading(c.AUTH_BY_OTP, 'post');
	const submit = React.useCallback((payload, onSuccess, onError) => {
		actions.authByOtpEmail(payload, (email) => {
			if (!email) {
				showModal({
					title: false,
					className: 'modal-md',
					content: (onClose) => (
						<RegisterEmailForOTP
							payload={payload}
							onSuccess={onSuccess}
							onClose={() => {
								onClose();
								onError();
							}}
						/>
					),
				});
				return;
			}
			onSuccess(`OTP has been sent to ${email}`);
		});
	}, []);
	return [isLoading, submit];
};

export const useSetEmailForOTP = () => {
	const isLoading = useApiLoading(c.AUTH_BY_OTP, 'post');
	const submit = React.useCallback((payload, onSuccess) => {
		actions.setEmailForOTP(payload, onSuccess);
	}, []);
	return [isLoading, submit];
};

export const useSaveProfile = () => {
	const isLoading = useApiLoading(c.PROFILE, 'post');
	const submit = React.useCallback((payload, onSuccess) => {
		actions.updateProfile(payload, onSuccess);
	}, []);
	return [isLoading, submit];
};

export const useSaveSymptoms = () => {
	const isLoading = useApiLoading(c.SYMPTOMS, 'put');
	const submit = React.useCallback((payload, onSuccess, onError) => {
		actions.updateSymptoms(payload, onSuccess, onError);
	}, []);
	return [isLoading, submit];
};

export const useScanResult = (scanned) => {
	const isLoading = useApiLoading(c.SCAN_RESULT, 'post');
	const result = useApiGet(c.SCAN_RESULT, {});
	React.useEffect(() => {
		if (!scanned) return;
		actions.scanQr(scanned);
	}, [scanned]);
	const clear = React.useCallback(() => {
		req.set(c.SCAN_RESULT, {});
	}, []);
	return [isLoading, result, clear];
};

export const useConfirmVisit = () => {
	const isLoading = useApiLoading(c.CONFIRM_VISIT, 'post');
	const result = useApiGet(c.CONFIRM_VISIT, {});
	const submit = React.useCallback((scanned) => {
		actions.confirmVisit(scanned);
	}, []);
	React.useEffect(() => {
		return () => {
			req.set(c.CONFIRM_VISIT, {});
		};
	}, []);
	return [isLoading, result, submit];
};

export const useAccountScanner = (qr_value, params, setScan) => {
	const isLoading = useApiLoading(c.ACCOUNT_SCANNER_RESULT, 'post');
	const result = useApiGet(c.ACCOUNT_SCANNER_RESULT, {});
	React.useEffect(() => {
		if (!qr_value) return;
		if (!params.purpose) {
			toastWarning('Please select purpose.');
			setScan(null);
			return;
		}
		actions.establishmentScannerScanQr({
			...(params || {}),
			uuid: qr_value,
		});
		// eslint-disable-next-line
	}, [qr_value]);
	const clear = React.useCallback(() => {
		req.set(c.ACCOUNT_SCANNER_RESULT, {});
	}, []);
	return [isLoading, result, clear];
};

export const usePurposeOptions = () => {
	const isLoading = useApiLoading(c.PURPOSE_OPTIONS, 'get');
	const list = useApiGet(c.PURPOSE_OPTIONS, []);
	React.useEffect(() => {
		actions.listPurposeOptions();
	}, []);
	return [isLoading, list];
};

const BASE_URL = process.env.REACT_APP_END_POINT;

export const useDownloadFile = ({
	downloadUrl = '',
	fileName = '',
	fileType = '',
}) => {
	const token = persistStorage.get('token');
	const [isLoading, setIsLoading] = useState(false);
	const downloadFile = useCallback(async () => {
		if (downloadUrl) {
			setIsLoading(true);
			toastInfo('Download Starting');
			const response = await fetch(`${BASE_URL}/${downloadUrl}`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
			});
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			const downloadLink = document.createElement('a');
			downloadLink.setAttribute('hidden', '');
			downloadLink.setAttribute('href', url);
			downloadLink.setAttribute('download', `${fileName}.${fileType}`);
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
			setIsLoading(false);
		}
	}, [downloadUrl, fileName, fileType, token]);
	return [downloadFile, isLoading];
};

export const useSendQRToEmail = (emails, onClose, setEmailSent) => {
	const token = persistStorage.get('token');
	const [isLoading, setIsLoading] = useState(false);
	const sendQRToEmail = useCallback(async () => {
		setIsLoading(true);
		await fetch(`${BASE_URL}/api/v1/current-trip/qr/send-qr-email`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
				Accept: 'application/json',
			},
			body: JSON.stringify({
				email: emails,
			}),
		});
		toastSuccess('Email Sent');
		setIsLoading(false);
		setEmailSent(false);
		onClose();
	}, [token, emails, onClose, setEmailSent]);
	return [sendQRToEmail, isLoading];
};
