import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

function FormSelect({
  label,
  name,
  onChange,
  value,
  icon,
  placeholder,
  options,
  isLabelVal,
  ...props
}) {
  const handleChange = ({ target }) => {
    onChange((state) => ({
      ...state,
      [name]: target.value,
    }));
  };
  const isActive = React.useMemo(() => {
    if (value === null && !placeholder) return false;
    return true;
  }, [value, placeholder]);
  return (
    <div
      className={`input-wrapper ${isActive ? 'active' : ''} ${
        icon ? 'with-icon' : ''
      }`}
    >
      {icon && <div className="icon">{icon}</div>}
      {!isEmpty(label) && (
        <div className="label">
          <div className="content">
            {label}
            {props?.required && <span className="text-red-500"> * </span>}
          </div>
        </div>
      )}
      <select onChange={handleChange} value={value} {...props}>
        {typeof placeholder !== 'boolean' && placeholder && (
          <option value="" disabled>
            {placeholder || '- Select -'}
          </option>
        )}
        {options.map((item) => (
          <option key={item.value} value={isLabelVal ? item.label : item.value}>
            {item.label}
          </option>
        ))}
      </select>
      {/* <div className="icon">
        <IconChevronDown />
      </div> */}
    </div>
  );
}

FormSelect.defaultProps = {
  label: '',
  placeholder: undefined,
  icon: null,
  isLabelVal: false,
  required: false,
};

FormSelect.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  isLabelVal: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Array).isRequired,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Object),
    PropTypes.func,
  ]),
};

export default React.memo(FormSelect);
