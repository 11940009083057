import React from 'react';
import PropTypes from 'prop-types';
// import { useApiLoading } from 'react-reqq-lite';
import { Link } from 'react-router-dom';
// import FormInput from 'modules/common/forms/FormInput';
import { ModalClose } from 'modules/common/components/Modal';
import { deleteAccount } from '../actions';
// import { DELETE_ACCOUNT } from '../constants';

function HowToDeleteAccountModal({ onClose }) {
  // const [form, setForm] = React.useState({
  //   confirmation: '',
  // });
  const [token] = React.useState(localStorage.getItem('token'));
  // const isLoading = useApiLoading(DELETE_ACCOUNT, 'remove');
  const handleDelete = () => {
    deleteAccount(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    });
  };
  return (
    <>
      <ModalClose onClose={onClose} />
      <div className="p-5">
        {!token ? (
          <div>
            You must be logged in to continue.{' '}
            <Link to="/app" onClick={onClose}>
              Click here to login.
            </Link>
          </div>
        ) : (
          <div>
            <div className="mb-2 text-center">
              Are you sure you want to delete your account?
              <br />
              {/* Enter &quot;<b>I CONFIRM</b>&quot; to proceed */}
            </div>
            {/* <div className="mb-2">
              <FormInput
                name="confirmation"
                onChange={setForm}
                value={form.confirmation}
              />
            </div> */}
            <div className="flex">
              <button
                className="btn danger w-full"
                type="button"
                onClick={handleDelete}
                // disabled={form.confirmation !== 'I CONFIRM' || isLoading}
              >
                Delete My Account
              </button>
              <button
                className="btn light w-full ml-2"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

HowToDeleteAccountModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default HowToDeleteAccountModal;
