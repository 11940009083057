import React, { useState, Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { showModal } from 'modules/common/components/Modal';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import GuestReturn from 'modules/common/modals/GuestReturnModal';
import { useGlobalState } from './globals';

function Header({ label, returnUrl, returnLabel, isGuest, isDisabled }) {
	const history = useHistory();

	const handleBack = () => {
		if (typeof returnUrl === 'function') {
			returnUrl();
			return;
		}
		history.push(returnUrl);
	};
	const handleDeleteProfile = (e) => {
		e.preventDefault();
		showModal({
			title: false,
			content: (onClose) => GuestReturn(onClose),
			className: 'modal-md',
		});
	};

	const { globalVariable, setGlobalVariable } = useGlobalState();
	const textSizes = [
		'text-xs',
		'text-sm',
		'text-base',
		'text-lg',
		'text-xl',
		'text-2xl',
		'text-3xl',
		'text-4xl',
		'text-5xl',
	];
	const increaseText = async (pm) => {
		const x = globalVariable;
		if (pm === 'plus' && globalVariable < 8) {
			setGlobalVariable(globalVariable + 1);
			localStorage.setItem('textIndex', x + 1);
		}
		if (pm === 'minus' && globalVariable !== 0) {
			setGlobalVariable(globalVariable - 1);
			localStorage.setItem('textIndex', x - 1);
		}
	};

	return (
		<>
			{!isGuest ? (
				<div className="text-center p-4 font-semibold relative">
					{returnUrl && (
						<div className="absolute left-3 top-3">
							<button
								className="btn light sm flex items-center space-x-2 justify-center"
								type="button"
								onClick={handleBack}
								disabled={isDisabled}
							>
								<ArrowLeftIcon className="w-4 h-4" />
								<span>{returnLabel}</span>
							</button>
						</div>
					)}
					<div className="mx-auto text-primary-500 text-lg">{label}</div>

					<div className="absolute top-1 right-3 text-primary-500">
						<Popover className="relative">
							{({ open }) => (
								<>
									<Popover.Button
										className={`
                      ${open ? 'text-white' : 'text-white/90'}
                      group inline-flex items-center rounded-md bg-[#5f83a9] px-4 mt-2 py-1 text-base font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
									>
										<span>-Aa+</span>
									</Popover.Button>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-200"
										enterFrom="opacity-0 translate-y-1"
										enterTo="opacity-100 translate-y-0"
										leave="transition ease-in duration-150"
										leaveFrom="opacity-100 translate-y-0"
										leaveTo="opacity-0 translate-y-1"
									>
										<Popover.Panel className="absolute left-1/4 z-10 mt-1 w-auto -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
											<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
												<div className="relative bg-white ">
													<div className="">
														<button
															className={
																globalVariable === 8
																	? 'opacity-25 cursor-not-allowed bg-lime-600 text-white mb-1 py-3 lg flex items-center space-x-2 justify-center w-28'
																	: 'bg-lime-600 text-white mb-1 py-3 lg flex items-center space-x-2 justify-center w-28'
															}
															type="button"
															onClick={() => {
																increaseText('plus');
															}}
														>
															Aa+
														</button>
														<button
															className={
																globalVariable === 0
																	? 'opacity-25 cursor-not-allowed bg-red-500 text-white py-3 lg flex items-center space-x-2 justify-center w-28'
																	: 'bg-red-500 text-white py-3 lg flex items-center space-x-2 justify-center w-28'
															}
															type="button"
															onClick={() => {
																increaseText('minus');
															}}
														>
															Aa-
														</button>
													</div>
												</div>
											</div>
										</Popover.Panel>
									</Transition>
								</>
							)}
						</Popover>
					</div>
				</div>
			) : (
				<div className="text-center pt-4 font-semibold relative">
					<div className="absolute left-3 top-3">
						<button
							className="btn light sm flex items-center space-x-2 justify-center"
							type="button"
							onClick={handleDeleteProfile}
						>
							<ArrowLeftIcon className="w-4 h-4" />
							<span>{returnLabel}</span>
						</button>
					</div>
					<div className="mx-auto text-primary-500 text-lg">{label}</div>

					<div className="absolute top-1 right-3 text-primary-500">
						<Popover className="relative">
							{({ open }) => (
								<>
									<Popover.Button
										className={`
                      ${open ? 'text-white' : 'text-white/90'}
                      group inline-flex items-center rounded-md bg-[#5f83a9] px-4 mt-2 py-1 text-base font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
									>
										<span>-Aa+</span>
									</Popover.Button>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-200"
										enterFrom="opacity-0 translate-y-1"
										enterTo="opacity-100 translate-y-0"
										leave="transition ease-in duration-150"
										leaveFrom="opacity-100 translate-y-0"
										leaveTo="opacity-0 translate-y-1"
									>
										<Popover.Panel className="absolute left-1/4 z-10 mt-1 w-auto -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
											<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
												<div className="relative bg-white ">
													<div className="">
														<button
															className={
																globalVariable === 8
																	? 'opacity-25 cursor-not-allowed bg-lime-600 text-white mb-1 py-3 lg flex items-center space-x-2 justify-center w-28'
																	: 'bg-lime-600 text-white mb-1 py-3 lg flex items-center space-x-2 justify-center w-28'
															}
															type="button"
															onClick={() => {
																increaseText('plus');
															}}
														>
															Aa+
														</button>
														<button
															className={
																globalVariable === 0
																	? 'opacity-25 cursor-not-allowed bg-red-500 text-white py-3 lg flex items-center space-x-2 justify-center w-28'
																	: 'bg-red-500 text-white py-3 lg flex items-center space-x-2 justify-center w-28'
															}
															type="button"
															onClick={() => {
																increaseText('minus');
															}}
														>
															Aa-
														</button>
													</div>
												</div>
											</div>
										</Popover.Panel>
									</Transition>
								</>
							)}
						</Popover>
					</div>
				</div>
			)}
		</>
	);
}

Header.defaultProps = {
	isGuest: false,
	isDisabled: false,
	returnUrl: null,
	returnLabel: 'Back',
};

Header.propTypes = {
	isGuest: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
	isDisabled: PropTypes.bool,
	returnLabel: PropTypes.string,
	label: PropTypes.string.isRequired,
	returnUrl: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Object),
	]),
};

export default Header;
