export const COUNTIES = [
  // { name: 'Afghanistan', iso2: 'AF', iso3: 'AFG', un: '4' },
  // { name: 'Aland Islands', iso2: 'AX', iso3: 'ALA', un: '248' },
  // { name: 'Albania', iso2: 'AL', iso3: 'ALB', un: '8' },
  // { name: 'Algeria', iso2: 'DZ', iso3: 'DZA', un: '12' },
  // { name: 'American Samoa', iso2: 'AS', iso3: 'ASM', un: '16' },
  // { name: 'Andorra', iso2: 'AD', iso3: 'AND', un: '20' },
  // { name: 'Angola', iso2: 'AO', iso3: 'AGO', un: '24' },
  // { name: 'Anguilla', iso2: 'AI', iso3: 'AIA', un: '660' },
  // { name: 'Antarctica', iso2: 'AQ', iso3: 'ATA', un: '10' },
  // { name: 'Antigua and Barbuda', iso2: 'AG', iso3: 'ATG', un: '28' },
  // { name: 'Argentina', iso2: 'AR', iso3: 'ARG', un: '32' },
  // { name: 'Armenia', iso2: 'AM', iso3: 'ARM', un: '51' },
  // { name: 'Aruba', iso2: 'AW', iso3: 'ABW', un: '533' },
  // { name: 'Australia', iso2: 'AU', iso3: 'AUS', un: '36' },
  // { name: 'Austria', iso2: 'AT', iso3: 'AUT', un: '40' },
  // { name: 'Azerbaijan', iso2: 'AZ', iso3: 'AZE', un: '31' },
  // { name: 'Bahamas', iso2: 'BS', iso3: 'BHS', un: '44' },
  // { name: 'Bahrain', iso2: 'BH', iso3: 'BHR', un: '48' },
  // { name: 'Bangladesh', iso2: 'BD', iso3: 'BGD', un: '50' },
  // { name: 'Barbados', iso2: 'BB', iso3: 'BRB', un: '52' },
  // { name: 'Belarus', iso2: 'BY', iso3: 'BLR', un: '112' },
  // { name: 'Belgium', iso2: 'BE', iso3: 'BEL', un: '56' },
  // { name: 'Belize', iso2: 'BZ', iso3: 'BLZ', un: '84' },
  // { name: 'Benin', iso2: 'BJ', iso3: 'BEN', un: '204' },
  // { name: 'Bermuda', iso2: 'BM', iso3: 'BMU', un: '60' },
  // { name: 'Bhutan', iso2: 'BT', iso3: 'BTN', un: '64' },
  // { name: 'Bolivia', iso2: 'BO', iso3: 'BOL', un: '68' },
  // { name: 'Bosnia and Herzegovina', iso2: 'BA', iso3: 'BIH', un: '70' },
  // { name: 'Botswana', iso2: 'BW', iso3: 'BWA', un: '72' },
  // { name: 'Bouvet Island', iso2: 'BV', iso3: 'BVT', un: '74' },
  // { name: 'Brazil', iso2: 'BR', iso3: 'BRA', un: '76' },
  // { name: 'British Virgin Islands', iso2: 'VG', iso3: 'VGB', un: '92' },
  // {
  //   name: 'British Indian Ocean Territory',
  //   iso2: 'IO',
  //   iso3: 'IOT',
  //   un: '86',
  // },
  // { name: 'Brunei Darussalam', iso2: 'BN', iso3: 'BRN', un: '96' },
  // { name: 'Bulgaria', iso2: 'BG', iso3: 'BGR', un: '100' },
  // { name: 'Burkina Faso', iso2: 'BF', iso3: 'BFA', un: '854' },
  // { name: 'Burundi', iso2: 'BI', iso3: 'BDI', un: '108' },
  // { name: 'Cambodia', iso2: 'KH', iso3: 'KHM', un: '116' },
  // { name: 'Cameroon', iso2: 'CM', iso3: 'CMR', un: '120' },
  // { name: 'Canada', iso2: 'CA', iso3: 'CAN', un: '124' },
  // { name: 'Cape Verde', iso2: 'CV', iso3: 'CPV', un: '132' },
  // { name: 'Cayman Islands', iso2: 'KY', iso3: 'CYM', un: '136' },
  // {
  //   name: 'Central African Republic',
  //   iso2: 'CF',
  //   iso3: 'CAF',
  //   un: '140',
  // },
  // { name: 'Chad', iso2: 'TD', iso3: 'TCD', un: '148' },
  // { name: 'Chile', iso2: 'CL', iso3: 'CHL', un: '152' },
  { name: 'China', iso2: 'CN', iso3: 'CHN', un: '156' },
  // { name: 'Hong Kong, SAR China', iso2: 'HK', iso3: 'HKG', un: '344' },
  // { name: 'Macao, SAR China', iso2: 'MO', iso3: 'MAC', un: '446' },
  // { name: 'Christmas Island', iso2: 'CX', iso3: 'CXR', un: '162' },
  // {
  //   name: 'Cocos (Keeling) Islands',
  //   iso2: 'CC',
  //   iso3: 'CCK',
  //   un: '166',
  // },
  // { name: 'Colombia', iso2: 'CO', iso3: 'COL', un: '170' },
  // { name: 'Comoros', iso2: 'KM', iso3: 'COM', un: '174' },
  // { name: 'Congo (Brazzaville)', iso2: 'CG', iso3: 'COG', un: '178' },
  // { name: 'Congo, (Kinshasa)', iso2: 'CD', iso3: 'COD', un: '180' },
  // { name: 'Cook Islands', iso2: 'CK', iso3: 'COK', un: '184' },
  // { name: 'Costa Rica', iso2: 'CR', iso3: 'CRI', un: '188' },
  // { name: "Côte d'Ivoire", iso2: 'CI', iso3: 'CIV', un: '384' },
  // { name: 'Croatia', iso2: 'HR', iso3: 'HRV', un: '191' },
  // { name: 'Cuba', iso2: 'CU', iso3: 'CUB', un: '192' },
  // { name: 'Cyprus', iso2: 'CY', iso3: 'CYP', un: '196' },
  // { name: 'Czech Republic', iso2: 'CZ', iso3: 'CZE', un: '203' },
  // { name: 'Denmark', iso2: 'DK', iso3: 'DNK', un: '208' },
  // { name: 'Djibouti', iso2: 'DJ', iso3: 'DJI', un: '262' },
  // { name: 'Dominica', iso2: 'DM', iso3: 'DMA', un: '212' },
  // { name: 'Dominican Republic', iso2: 'DO', iso3: 'DOM', un: '214' },
  // { name: 'Ecuador', iso2: 'EC', iso3: 'ECU', un: '218' },
  // { name: 'Egypt', iso2: 'EG', iso3: 'EGY', un: '818' },
  // { name: 'El Salvador', iso2: 'SV', iso3: 'SLV', un: '222' },
  // { name: 'Equatorial Guinea', iso2: 'GQ', iso3: 'GNQ', un: '226' },
  // { name: 'Eritrea', iso2: 'ER', iso3: 'ERI', un: '232' },
  // { name: 'Estonia', iso2: 'EE', iso3: 'EST', un: '233' },
  // { name: 'Ethiopia', iso2: 'ET', iso3: 'ETH', un: '231' },
  // {
  //   name: 'Falkland Islands (Malvinas)',
  //   iso2: 'FK',
  //   iso3: 'FLK',
  //   un: '238',
  // },
  // { name: 'Faroe Islands', iso2: 'FO', iso3: 'FRO', un: '234' },
  // { name: 'Fiji', iso2: 'FJ', iso3: 'FJI', un: '242' },
  // { name: 'Finland', iso2: 'FI', iso3: 'FIN', un: '246' },
  // { name: 'France', iso2: 'FR', iso3: 'FRA', un: '250' },
  // { name: 'French Guiana', iso2: 'GF', iso3: 'GUF', un: '254' },
  // { name: 'French Polynesia', iso2: 'PF', iso3: 'PYF', un: '258' },
  // {
  //   name: 'French Southern Territories',
  //   iso2: 'TF',
  //   iso3: 'ATF',
  //   un: '260',
  // },
  // { name: 'Gabon', iso2: 'GA', iso3: 'GAB', un: '266' },
  // { name: 'Gambia', iso2: 'GM', iso3: 'GMB', un: '270' },
  // { name: 'Georgia', iso2: 'GE', iso3: 'GEO', un: '268' },
  // { name: 'Germany', iso2: 'DE', iso3: 'DEU', un: '276' },
  // { name: 'Ghana', iso2: 'GH', iso3: 'GHA', un: '288' },
  // { name: 'Gibraltar', iso2: 'GI', iso3: 'GIB', un: '292' },
  // { name: 'Greece', iso2: 'GR', iso3: 'GRC', un: '300' },
  // { name: 'Greenland', iso2: 'GL', iso3: 'GRL', un: '304' },
  // { name: 'Grenada', iso2: 'GD', iso3: 'GRD', un: '308' },
  // { name: 'Guadeloupe', iso2: 'GP', iso3: 'GLP', un: '312' },
  // { name: 'Guam', iso2: 'GU', iso3: 'GUM', un: '316' },
  // { name: 'Guatemala', iso2: 'GT', iso3: 'GTM', un: '320' },
  // { name: 'Guernsey', iso2: 'GG', iso3: 'GGY', un: '831' },
  // { name: 'Guinea', iso2: 'GN', iso3: 'GIN', un: '324' },
  // { name: 'Guinea-Bissau', iso2: 'GW', iso3: 'GNB', un: '624' },
  // { name: 'Guyana', iso2: 'GY', iso3: 'GUY', un: '328' },
  // { name: 'Haiti', iso2: 'HT', iso3: 'HTI', un: '332' },
  // {
  //   name: 'Heard and Mcdonald Islands',
  //   iso2: 'HM',
  //   iso3: 'HMD',
  //   un: '334',
  // },
  // {
  //   name: 'Holy See (Vatican City State)',
  //   iso2: 'VA',
  //   iso3: 'VAT',
  //   un: '336',
  // },
  // { name: 'Honduras', iso2: 'HN', iso3: 'HND', un: '340' },
  // { name: 'Hungary', iso2: 'HU', iso3: 'HUN', un: '348' },
  // { name: 'Iceland', iso2: 'IS', iso3: 'ISL', un: '352' },
  // { name: 'India', iso2: 'IN', iso3: 'IND', un: '356' },
  // { name: 'Indonesia', iso2: 'ID', iso3: 'IDN', un: '360' },
  // {
  //   name: 'Iran, Islamic Republic of',
  //   iso2: 'IR',
  //   iso3: 'IRN',
  //   un: '364',
  // },
  // { name: 'Iraq', iso2: 'IQ', iso3: 'IRQ', un: '368' },
  // { name: 'Ireland', iso2: 'IE', iso3: 'IRL', un: '372' },
  // { name: 'Isle of Man', iso2: 'IM', iso3: 'IMN', un: '833' },
  // { name: 'Israel', iso2: 'IL', iso3: 'ISR', un: '376' },
  // { name: 'Italy', iso2: 'IT', iso3: 'ITA', un: '380' },
  // { name: 'Jamaica', iso2: 'JM', iso3: 'JAM', un: '388' },
  { name: 'Japan', iso2: 'JP', iso3: 'JPN', un: '392' },
  // { name: 'Jersey', iso2: 'JE', iso3: 'JEY', un: '832' },
  // { name: 'Jordan', iso2: 'JO', iso3: 'JOR', un: '400' },
  // { name: 'Kazakhstan', iso2: 'KZ', iso3: 'KAZ', un: '398' },
  // { name: 'Kenya', iso2: 'KE', iso3: 'KEN', un: '404' },
  // { name: 'Kiribati', iso2: 'KI', iso3: 'KIR', un: '296' },
  { name: 'Korea (North)', iso2: 'KP', iso3: 'PRK', un: '408' },
  { name: 'Korea (South)', iso2: 'KR', iso3: 'KOR', un: '410' },
  // { name: 'Kuwait', iso2: 'KW', iso3: 'KWT', un: '414' },
  // { name: 'Kyrgyzstan', iso2: 'KG', iso3: 'KGZ', un: '417' },
  // { name: 'Lao PDR', iso2: 'LA', iso3: 'LAO', un: '418' },
  // { name: 'Latvia', iso2: 'LV', iso3: 'LVA', un: '428' },
  // { name: 'Lebanon', iso2: 'LB', iso3: 'LBN', un: '422' },
  // { name: 'Lesotho', iso2: 'LS', iso3: 'LSO', un: '426' },
  // { name: 'Liberia', iso2: 'LR', iso3: 'LBR', un: '430' },
  // { name: 'Libya', iso2: 'LY', iso3: 'LBY', un: '434' },
  // { name: 'Liechtenstein', iso2: 'LI', iso3: 'LIE', un: '438' },
  // { name: 'Lithuania', iso2: 'LT', iso3: 'LTU', un: '440' },
  // { name: 'Luxembourg', iso2: 'LU', iso3: 'LUX', un: '442' },
  // {
  //   name: 'Macedonia, Republic of',
  //   iso2: 'MK',
  //   iso3: 'MKD',
  //   un: '807',
  // },
  // { name: 'Madagascar', iso2: 'MG', iso3: 'MDG', un: '450' },
  // { name: 'Malawi', iso2: 'MW', iso3: 'MWI', un: '454' },
  // { name: 'Malaysia', iso2: 'MY', iso3: 'MYS', un: '458' },
  // { name: 'Maldives', iso2: 'MV', iso3: 'MDV', un: '462' },
  // { name: 'Mali', iso2: 'ML', iso3: 'MLI', un: '466' },
  // { name: 'Malta', iso2: 'MT', iso3: 'MLT', un: '470' },
  // { name: 'Marshall Islands', iso2: 'MH', iso3: 'MHL', un: '584' },
  // { name: 'Martinique', iso2: 'MQ', iso3: 'MTQ', un: '474' },
  // { name: 'Mauritania', iso2: 'MR', iso3: 'MRT', un: '478' },
  // { name: 'Mauritius', iso2: 'MU', iso3: 'MUS', un: '480' },
  // { name: 'Mayotte', iso2: 'YT', iso3: 'MYT', un: '175' },
  // { name: 'Mexico', iso2: 'MX', iso3: 'MEX', un: '484' },
  // {
  //   name: 'Micronesia, Federated States of',
  //   iso2: 'FM',
  //   iso3: 'FSM',
  //   un: '583',
  // },
  // { name: 'Moldova', iso2: 'MD', iso3: 'MDA', un: '498' },
  // { name: 'Monaco', iso2: 'MC', iso3: 'MCO', un: '492' },
  // { name: 'Mongolia', iso2: 'MN', iso3: 'MNG', un: '496' },
  // { name: 'Montenegro', iso2: 'ME', iso3: 'MNE', un: '499' },
  // { name: 'Montserrat', iso2: 'MS', iso3: 'MSR', un: '500' },
  // { name: 'Morocco', iso2: 'MA', iso3: 'MAR', un: '504' },
  // { name: 'Mozambique', iso2: 'MZ', iso3: 'MOZ', un: '508' },
  // { name: 'Myanmar', iso2: 'MM', iso3: 'MMR', un: '104' },
  // { name: 'Namibia', iso2: 'NA', iso3: 'NAM', un: '516' },
  // { name: 'Nauru', iso2: 'NR', iso3: 'NRU', un: '520' },
  // { name: 'Nepal', iso2: 'NP', iso3: 'NPL', un: '524' },
  // { name: 'Netherlands', iso2: 'NL', iso3: 'NLD', un: '528' },
  // { name: 'Netherlands Antilles', iso2: 'AN', iso3: 'ANT', un: '530' },
  // { name: 'New Caledonia', iso2: 'NC', iso3: 'NCL', un: '540' },
  // { name: 'New Zealand', iso2: 'NZ', iso3: 'NZL', un: '554' },
  // { name: 'Nicaragua', iso2: 'NI', iso3: 'NIC', un: '558' },
  // { name: 'Niger', iso2: 'NE', iso3: 'NER', un: '562' },
  // { name: 'Nigeria', iso2: 'NG', iso3: 'NGA', un: '566' },
  // { name: 'Niue', iso2: 'NU', iso3: 'NIU', un: '570' },
  // { name: 'Norfolk Island', iso2: 'NF', iso3: 'NFK', un: '574' },
  {
    name: 'Northern Mariana Islands',
    iso2: 'MP',
    iso3: 'MNP',
    un: '580',
  },
  // { name: 'Norway', iso2: 'NO', iso3: 'NOR', un: '578' },
  // { name: 'Oman', iso2: 'OM', iso3: 'OMN', un: '512' },
  // { name: 'Pakistan', iso2: 'PK', iso3: 'PAK', un: '586' },
  // { name: 'Palau', iso2: 'PW', iso3: 'PLW', un: '585' },
  // { name: 'Palestinian Territory', iso2: 'PS', iso3: 'PSE', un: '275' },
  // { name: 'Panama', iso2: 'PA', iso3: 'PAN', un: '591' },
  // { name: 'Papua New Guinea', iso2: 'PG', iso3: 'PNG', un: '598' },
  // { name: 'Paraguay', iso2: 'PY', iso3: 'PRY', un: '600' },
  // { name: 'Peru', iso2: 'PE', iso3: 'PER', un: '604' },
  { name: 'Philippines', iso2: 'PH', iso3: 'PHL', un: '608' },
  // { name: 'Pitcairn', iso2: 'PN', iso3: 'PCN', un: '612' },
  // { name: 'Poland', iso2: 'PL', iso3: 'POL', un: '616' },
  // { name: 'Portugal', iso2: 'PT', iso3: 'PRT', un: '620' },
  // { name: 'Puerto Rico', iso2: 'PR', iso3: 'PRI', un: '630' },
  // { name: 'Qatar', iso2: 'QA', iso3: 'QAT', un: '634' },
  // { name: 'Réunion', iso2: 'RE', iso3: 'REU', un: '638' },
  // { name: 'Romania', iso2: 'RO', iso3: 'ROU', un: '642' },
  // { name: 'Russian Federation', iso2: 'RU', iso3: 'RUS', un: '643' },
  // { name: 'Rwanda', iso2: 'RW', iso3: 'RWA', un: '646' },
  // { name: 'Saint-Barthélemy', iso2: 'BL', iso3: 'BLM', un: '652' },
  // { name: 'Saint Helena', iso2: 'SH', iso3: 'SHN', un: '654' },
  // { name: 'Saint Kitts and Nevis', iso2: 'KN', iso3: 'KNA', un: '659' },
  // { name: 'Saint Lucia', iso2: 'LC', iso3: 'LCA', un: '662' },
  // {
  //   name: 'Saint-Martin (French part)',
  //   iso2: 'MF',
  //   iso3: 'MAF',
  //   un: '663',
  // },
  // {
  //   name: 'Saint Pierre and Miquelon',
  //   iso2: 'PM',
  //   iso3: 'SPM',
  //   un: '666',
  // },
  // {
  //   name: 'Saint Vincent and Grenadines',
  //   iso2: 'VC',
  //   iso3: 'VCT',
  //   un: '670',
  // },
  // { name: 'Samoa', iso2: 'WS', iso3: 'WSM', un: '882' },
  // { name: 'San Marino', iso2: 'SM', iso3: 'SMR', un: '674' },
  // { name: 'Sao Tome and Principe', iso2: 'ST', iso3: 'STP', un: '678' },
  // { name: 'Saudi Arabia', iso2: 'SA', iso3: 'SAU', un: '682' },
  // { name: 'Senegal', iso2: 'SN', iso3: 'SEN', un: '686' },
  // { name: 'Serbia', iso2: 'RS', iso3: 'SRB', un: '688' },
  // { name: 'Seychelles', iso2: 'SC', iso3: 'SYC', un: '690' },
  // { name: 'Sierra Leone', iso2: 'SL', iso3: 'SLE', un: '694' },
  // { name: 'Singapore', iso2: 'SG', iso3: 'SGP', un: '702' },
  // { name: 'Slovakia', iso2: 'SK', iso3: 'SVK', un: '703' },
  // { name: 'Slovenia', iso2: 'SI', iso3: 'SVN', un: '705' },
  // { name: 'Solomon Islands', iso2: 'SB', iso3: 'SLB', un: '90' },
  // { name: 'Somalia', iso2: 'SO', iso3: 'SOM', un: '706' },
  // { name: 'South Africa', iso2: 'ZA', iso3: 'ZAF', un: '710' },
  // {
  //   name: 'South Georgia and the South Sandwich Islands',
  //   iso2: 'GS',
  //   iso3: 'SGS',
  //   un: '239',
  // },
  // { name: 'South Sudan', iso2: 'SS', iso3: 'SSD', un: '728' },
  // { name: 'Spain', iso2: 'ES', iso3: 'ESP', un: '724' },
  // { name: 'Sri Lanka', iso2: 'LK', iso3: 'LKA', un: '144' },
  // { name: 'Sudan', iso2: 'SD', iso3: 'SDN', un: '736' },
  // { name: 'Suriname', iso2: 'SR', iso3: 'SUR', un: '740' },
  // {
  //   name: 'Svalbard and Jan Mayen Islands',
  //   iso2: 'SJ',
  //   iso3: 'SJM',
  //   un: '744',
  // },
  // { name: 'Swaziland', iso2: 'SZ', iso3: 'SWZ', un: '748' },
  // { name: 'Sweden', iso2: 'SE', iso3: 'SWE', un: '752' },
  // { name: 'Switzerland', iso2: 'CH', iso3: 'CHE', un: '756' },
  // {
  //   name: 'Syrian Arab Republic (Syria)',
  //   iso2: 'SY',
  //   iso3: 'SYR',
  //   un: '760',
  // },
  // {
  //   name: 'Taiwan',
  //   iso2: 'TW',
  //   iso3: 'TWN',
  //   un: '158',
  // },
  // { name: 'Tajikistan', iso2: 'TJ', iso3: 'TJK', un: '762' },
  // {
  //   name: 'Tanzania',
  //   iso2: 'TZ',
  //   iso3: 'TZA',
  //   un: '834',
  // },
  // { name: 'Thailand', iso2: 'TH', iso3: 'THA', un: '764' },
  // { name: 'Timor-Leste', iso2: 'TL', iso3: 'TLS', un: '626' },
  // { name: 'Togo', iso2: 'TG', iso3: 'TGO', un: '768' },
  // { name: 'Tokelau', iso2: 'TK', iso3: 'TKL', un: '772' },
  // { name: 'Tonga', iso2: 'TO', iso3: 'TON', un: '776' },
  // { name: 'Trinidad and Tobago', iso2: 'TT', iso3: 'TTO', un: '780' },
  // { name: 'Tunisia', iso2: 'TN', iso3: 'TUN', un: '788' },
  // { name: 'Turkey', iso2: 'TR', iso3: 'TUR', un: '792' },
  // { name: 'Turkmenistan', iso2: 'TM', iso3: 'TKM', un: '795' },
  // {
  //   name: 'Turks and Caicos Islands',
  //   iso2: 'TC',
  //   iso3: 'TCA',
  //   un: '796',
  // },
  // { name: 'Tuvalu', iso2: 'TV', iso3: 'TUV', un: '798' },
  // { name: 'Uganda', iso2: 'UG', iso3: 'UGA', un: '800' },
  // { name: 'Ukraine', iso2: 'UA', iso3: 'UKR', un: '804' },
  // { name: 'United Arab Emirates', iso2: 'AE', iso3: 'ARE', un: '784' },
  // { name: 'United Kingdom', iso2: 'GB', iso3: 'GBR', un: '826' },
  {
    name: 'United States of America',
    iso2: 'US',
    iso3: 'USA',
    un: '840',
  },
  // {
  //   name: 'US Minor Outlying Islands',
  //   iso2: 'UM',
  //   iso3: 'UMI',
  //   un: '581',
  // },
  // { name: 'Uruguay', iso2: 'UY', iso3: 'URY', un: '858' },
  // { name: 'Uzbekistan', iso2: 'UZ', iso3: 'UZB', un: '860' },
  // { name: 'Vanuatu', iso2: 'VU', iso3: 'VUT', un: '548' },
  // {
  //   name: 'Venezuela (Bolivarian Republic)',
  //   iso2: 'VE',
  //   iso3: 'VEN',
  //   un: '862',
  // },
  // { name: 'Viet Nam', iso2: 'VN', iso3: 'VNM', un: '704' },
  // { name: 'Virgin Islands, US', iso2: 'VI', iso3: 'VIR', un: '850' },
  // {
  //   name: 'Wallis and Futuna Islands',
  //   iso2: 'WF',
  //   iso3: 'WLF',
  //   un: '876',
  // },
  // { name: 'Western Sahara', iso2: 'EH', iso3: 'ESH', un: '732' },
  // { name: 'Yemen', iso2: 'YE', iso3: 'YEM', un: '887' },
  // { name: 'Zambia', iso2: 'ZM', iso3: 'ZMB', un: '894' },
  // { name: 'Zimbabwe', iso2: 'ZW', iso3: 'ZWE', un: '716' },
];

export const countryOptions = COUNTIES.map((x) => ({
  label: x.name,
  value: x.iso2,
}));
