import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import EN from 'assets/images/language/united.svg';
import JP from 'assets/images/language/japan.svg';
import KR from 'assets/images/language/south.svg';
import CN from 'assets/images/language/china.svg';
import { req, useApiGet } from 'react-reqq-lite';
import { MdOutlineGTranslate } from 'react-icons/md';
import { showModal } from './Modal';
import LANG from './lang';

const OPTIONS = [
	{ value: 'EN', label: 'English', icon: EN },
	{ value: 'JP', label: 'Japanese', icon: JP },
	{ value: 'KR', label: 'Korean', icon: KR },
	{ value: 'CN', label: 'Chinese', icon: CN },
];

const defaultLang = localStorage.getItem('lang');

const setLanguage = (newLang) => {
	localStorage.setItem('lang', newLang);
	req.set('APP/lang', newLang);
};

const promptChangeLanguage = () => {
	showModal({
		title: 'Select Language',
		content: (onClose) => (
			<div className="px-5 pb-5">
				<div className="grid grid-cols-1 gap-3">
					{OPTIONS.map((x) => (
						<button
							className="flex items-center justify-between rounded-lg shadow-sm bg-white text-gray-300 hover:border-primary-500 hover:text-primary-500 p-3 font-medium transition focus:outline-none border border-gray-100"
							key={x.value}
							type="button"
							onClick={() => {
								setLanguage(x.value);
								onClose();
							}}
						>
							<div className="text-sm text-gray-800 font-semibold">
								{x.label}
							</div>
							<div className="font-bold leading-none rounded border px-2 py-1">
								<img src={x.icon} alt={x.label} className="w-7 h-5 mb-1" />
								{x.value}
							</div>
						</button>
					))}
				</div>
			</div>
		),
	});
};

export const LanguageSwitcher = ({ iconClassName, ...props }) => {
	const [show, setShow] = React.useState(false);
	const btnDropdownRef = React.createRef();
	const popoverDropdownRef = React.createRef();
	const lang = useApiGet('APP/lang', defaultLang);
	const icon = React.useMemo(() => {
		const option = OPTIONS?.find((c) => c.value === lang);
		return option?.icon;
	}, [lang]);

	const unselectedLangs = React.useMemo(() => {
		const option = OPTIONS?.filter((c) => c.value !== lang);
		return option;
	}, [lang]);

	// const handleClick = (e) => {
	//   e.preventDefault();
	//   promptChangeLanguage();
	// };
	return (
		<div className="flex flex-wrap">
			<div className="w-full px-2 relative">
				<div className="inline-flex align-middle w-full">
					<button
						{...props}
						type="button"
						ref={btnDropdownRef}
						onClick={() => setShow(!show)}
						className=" bg-white flex py-2 px-1 rounded drop-shadow-lg lg:drop-shadow-none hover:scale-110 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
					>
						<MdOutlineGTranslate className={iconClassName} />
						<img src={icon} className="w-8 mx-1" alt={lang} />
					</button>
				</div>
				<div
					ref={popoverDropdownRef}
					className={`${
						show ? 'block' : 'hidden'
					} z-50 bg-white rounded w-[75%] float-left absolute right-3 origin-top-right rounded-b-lg space-y-4 p-2 `}
				>
					{unselectedLangs.map((x) => (
						<button
							className="bg-transparent whitespace-nowrap w-full border"
							key={x.value}
							type="button"
							onClick={() => {
								setLanguage(x.value);
								setShow(false);
							}}
						>
							<img src={x.icon} alt={x.label} className="w-full" />
						</button>
					))}
				</div>
			</div>
		</div>
	);
};

LanguageSwitcher.defaultProps = {
	iconClassName: 'inline-block h-6 w-6',
};

LanguageSwitcher.propTypes = {
	iconClassName: PropTypes.string,
};

export const useLanguage = () => {
	const lang = useApiGet('APP/lang', defaultLang);
	const text = React.useCallback(
		(key) =>
			get(LANG, `${key}.${lang}`) || get(LANG, `${key}.EN`) || '- NOT FOUND -',
		[lang]
	);
	return [text, lang];
};

const useInitLanguage = () => {
	const lang = useApiGet('APP/lang', defaultLang);
	React.useEffect(() => {
		if (!lang && window.location.href.includes('app')) {
			promptChangeLanguage();
		}
	}, [lang]);
	return [lang, setLanguage];
};

function Language() {
	useInitLanguage();
	return null;
}

Language.propTypes = {};

export default Language;
