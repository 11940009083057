import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

function FormInput({
  label,
  name,
  onChange,
  value,
  icon,
  validation,
  validClassName,
  invalidClassName,
  maxLength,
  type,
  title,
  ...props
}) {
  const handleChange = ({ target }) => {
    if (maxLength > 0 && `${target.value}`.length > maxLength) return;
    onChange((state) => ({
      ...state,
      [name]: target.value,
    }));
  };
  const isActive = React.useMemo(() => {
    if (isEmpty(value)) return false;
    return true;
  }, [value]);

  // const isValid = React.useMemo(() => {
  //   if (isEmpty(value) || isEmpty(validation)) return true;
  //   const regex = new RegExp(validation);
  //   return regex.test(value);
  // }, [value, validation]);
  const borderValidation = React.useMemo(() => {
    if (isEmpty(value) || isEmpty(validation)) return '';
    const regex = new RegExp(validation);
    return regex.test(value) ? validClassName : invalidClassName;
  }, [value, validation, validClassName, invalidClassName]);
  const otherProps = React.useMemo(() => {
    if (type === 'number')
      return {
        pattern: '[0-9]*',
        onKeyPress: (e) => {
          const charCode = typeof e.which === 'undefined' ? e.keyCode : e.which;
          const charStr = String.fromCharCode(charCode);
          if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
        },
      };
    if (type === 'email') {
      const emailPattern = '[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,63}$';
      return {
        pattern: emailPattern,
      };
    }
    if (type === 'text' && validation) {
      return {
        pattern: validation.toString(),
      };
    }
    return {};
  }, [type, validation]);

  // const isValidEmail = React.useMemo(() => {
  //   if (isEmpty(value)) return true;
  //   const rgx = new RegExp(/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/);
  //   return rgx.test(value);
  // }, [value]);

  return (
    <div
      className={`input-wrapper ${isActive ? 'active' : ''} ${
        icon ? 'with-icon' : ''
      } ${borderValidation}`}
    >
      {icon && <div className="icon">{icon}</div>}
      {!isEmpty(label) && (
        <div className="label">
          <div className="content">
            <span>
              {label}
              {props?.required && <span className="text-red-500"> * </span>}
            </span>
            {/* {type === 'email' &&
               !isValidEmail && <span className="ml-2 font-bold">INVALID</span>
             } */}
          </div>
        </div>
      )}
      <input
        onChange={handleChange}
        value={value}
        type={type}
        autoComplete="none"
        list="autocompleteOff"
        title={title}
        maxLength="100"
        {...otherProps}
        {...props}
      />
    </div>
  );
}

FormInput.defaultProps = {
  label: '',
  icon: null,
  validation: '',
  validClassName: 'valid',
  invalidClassName: 'invalid',
  maxLength: '0',
  type: 'text',
  required: false,
  title: '',
};

FormInput.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.instanceOf(Object),
  validation: PropTypes.string,
  validClassName: PropTypes.string,
  invalidClassName: PropTypes.string,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  title: PropTypes.string,
};

export default React.memo(FormInput);
